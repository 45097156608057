.peopleQuadrantContainer {
  display: flex;
  align-items: center;
  padding-top: 30px;
}

.peopleQuadrantMainText {
  padding-left: 10%;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #1e3264;
}

.peopleQuadrantSubText {
  padding-left: 5%;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #1e3264;
}

.peopleQuadrantSubContainer {
  display: flex;
  align-items: center;
  padding-top: 8px;
}
