.dailyPulseContainer {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.dailyPulseLeftSection {
  width: 66.7%;
  background-color: #fff;
  overflow-y: scroll;
  padding-bottom: 8vh;
  height: calc(100vh - 60px);
}

.pulseGraphContainer {
  margin-top: 8% !important;
}

.graphHeading {
  width: 95%;
  height: 37.41px;
  background: #f6f7f9;
  margin-left: 25px;
}

.header {
  padding-left: 15.53px;
  height: 40px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  display: flex;
  align-items: center;
  color: #3b4a57;
}

.dailyPulseScreenHeaderContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffa894;
  height: 74px;
  padding: 0 24px;
  position: absolute;
  width: 55.95%;
  z-index: 3;
}

.displayGraph {
  margin-top: 33px !important;
}

.lineCSS {
  /* width: 114.26px; */
  width: 100px;
  /* margin-left: -50%; */
  margin-top: 30px;
  border-top: 1px solid #c2c9d1;
}
.toolWrapper {
  width: 200.59px;
  height: 44.15px;
  left: 454.23px;
  top: 771.97px;
  background: #ffffff;
  box-shadow: 0px 15px 22px rgba(0, 0, 0, 0.06), 0px 3px 8px rgba(0, 0, 0, 0.08),
    0px 1px 1px rgba(0, 0, 0, 0.08), 0px 3px 1px rgba(0, 0, 0, 0.04);
  border-radius: 24px;
}

.dailyPulseScreenHeader {
  font-family: Montserrat;
  font-weight: bold;
  font-size: 25px;
  line-height: 32px;
  color: #1e3264;
}

.dailyPulseRightSection {
  width: 33.3%;
  background-color: #fff;
  overflow-y: scroll;
  height: calc(100vh - 60px);
}

.dailyPulseRightHeading {
  padding-left: 24px;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 18px;
  line-height: 74px;
  color: #1e3264;
  background-color: #d9bfad;
  height: 74px;
}

.dailyPulseFooterContainer {
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 8px;
  position: fixed;
  bottom: 0;
  width: calc(54% - 10px);
  background-color: #fff;
  justify-content: center;
  z-index: 1;
}

.mapContainer {
  /* padding-top: 10%; */
  /* padding-left: 10%; */
  width: 35vw;
  height: 45vh;
  background-color: 'red' !important;
  border-radius: 16px;
}

.resetButtonText {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  padding: 0 24px;
  color: #0051c2;
  cursor: pointer;
}

.toolTipStyle {
  display: flex;
  /* justify-content: space-between; */
  flex-direction: column;
  align-content: center;
  /* align-items: space-around; */
  padding-left: 7%;
}

.toolTipTextStyle {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  width: 160px;
  height: 30px;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 16px;
}

.newMap {
  width: 100%;
  height: 100%;
  padding-top: 10%;
  padding-left: 22%;
  border-radius: 16px;
}

.mainMapsContainer {
  padding-top: 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

#dailyPulseNotifyButton > span {
  padding: 12px 24px;
}

#root
  > div.mainContainer
  > div:nth-child(2)
  > div
  > div.dailyPulseContainer
  > div.dailyPulseLeftSection
  > div.mapContainer
  > div.map
  > div
  > div
  > div
  > div:nth-child(2)
  > div:nth-child(2)
  > div
  > div:nth-child(4)
  > div:nth-child(2)
  > div
  > div
  > div {
  min-width: 190px !important;
  padding-left: 30px;
}

#root
  > div.mainContainer
  > div:nth-child(2)
  > div
  > div.dailyPulseContainer
  > div.dailyPulseLeftSection
  > div.mapContainer
  > div {
  width: 35% !important;
  height: 55% !important;
  position: relative;
  padding-left: 2%;
}

/* #root > div.mainContainer > div:nth-child(2) > div > div.dailyPulseContainer > div.dailyPulseLeftSection > div.mapContainer > div > div > div > div > div:nth-child(2) > div:nth-child(2) > div > div:nth-child(4) > div:nth-child(2) > div > div::after{
  display: none !important;
} */

/* #root > div.mainContainer > div:nth-child(2) > div > div.dailyPulseContainer > div.dailyPulseLeftSection > div.newMap > div{
  width: 12% !important;
  height: 55% !important;
  position: relative;
  padding-top: 5%;
} */

#root
  > div.mainContainer
  > div:nth-child(2)
  > div
  > div.dailyPulseContainer
  > div.dailyPulseLeftSection
  > div.mainMapsContainer
  > div.newMap
  > div {
  /* position: absolute !important; */
  width: 20% !important;
  height: 55% !important;
}

/* #root>div.mainContainer>div:nth-child(2)>div>div.dailyPulseContainer>div.dailyPulseLeftSection>div.mainMapsContainer>div.mapContainer>div {
  position: absolute !important;
  width: 100% !important;
  height: 95% !important;
} */

#root
  > div.mainContainer
  > div:nth-child(2)
  > div
  > div.dailyPulseContainer
  > div.dailyPulseLeftSection
  > div.mainMapsContainer
  > div.newMap
  > div
  > div
  > div
  > div
  > div:nth-child(2)
  > div:nth-child(2)
  > div
  > div:nth-child(4)
  > div:nth-child(2)
  > div
  > div::after {
  display: none !important;
}

#root
  > div.mainContainer
  > div:nth-child(2)
  > div
  > div.dailyPulseContainer
  > div.dailyPulseLeftSection
  > div.mainMapsContainer
  > div.mapContainer
  > div
  > div
  > div
  > div
  > div:nth-child(2)
  > div:nth-child(2)
  > div
  > div:nth-child(4)
  > div:nth-child(2)
  > div
  > div::after {
  display: none !important;
}

/*

.weatherScreenDate {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #1e3264;
}


.weatherBadgeImage {
  width: 88px;
  aspect-ratio: 1;
  margin-left: 16px;
}

.radioLabelHeading {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.radioLabelDescription {
  font-family: Montserrat;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: #3b4a57;
}

#weatherNotifyButton > span {
  padding: 12px 24px;
}
.resetText {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  padding: 0 24px;
  color: #a7a7a7;
  cursor: pointer;
}

.resetTextEnable {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  padding: 0 24px;
  color: #0051c2;
  cursor: pointer;
}
#markedRadio > div > div {
  border: 0.5rem solid #a7a7a7;
}
.weather-source {
  color: #73808c;
  font-family: 'Montserrat';
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 10px;
  letter-spacing: 0em;
}
@media (max-width: 1370px) {
  .weatherScreenHeaderContainer {
    width: 55.5%;
  }
} */
.SvgMap {
  /* padding-top: 10%; */
  /* padding-left: 30%; */
  height: fit-content;
  display: flex;
  flex-direction: column;
}

.reg2 {
  margin-top: -77px;
  margin-left: 52px;
}

.reg3 {
  margin-left: -6px;
  margin-top: -84px;
}

.mapHeading {
  height: fit-content !important;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  display: flex;
  align-items: center;
  color: #3b4a57;
  padding-bottom: 4vh;
}

.weekHeading {
  width: 300px;
  display: flex;
  justify-content: space-between;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  /* line-height: 16px; */
  text-align: center;
  color: #1e3264;
}

.dailyPulseDateButton {
  background-color: transparent;
  border: transparent;
  cursor: pointer;
}

.map {
  position: relative !important;
}

.moodImage {
  margin-right: 7%;
}

#root
  > div.mainContainer
  > div:nth-child(2)
  > div
  > div.dailyPulseContainer
  > div.dailyPulseLeftSection
  > div.mainMapsContainer
  > div.mapContainer
  > div.map
  > div
  > div
  > div
  > div:nth-child(2)
  > div:nth-child(2)
  > div
  > div:nth-child(4)
  > div:nth-child(2)
  > div
  > div
  > div {
  min-width: 220px !important;
  min-height: 150px !important;
}

#root
  > div.mainContainer
  > div:nth-child(2)
  > div
  > div.dailyPulseContainer
  > div.dailyPulseLeftSection
  > div.mainMapsContainer
  > div.mapContainer
  > div.map
  > div
  > div
  > div
  > div:nth-child(2)
  > div:nth-child(2)
  > div
  > div:nth-child(4)
  > div:nth-child(2)
  > div
  > div
  > div
  > button {
  display: none !important;
}

.displaySquare1 {
  width: 11.4%;
  height: 213.67px;
  left: 837.67px;
  top: 716.18px;
  border: 3px solid #edbdbd;
  margin-top: -247px;
}

.displaySquare2 {
  width: 8%;
  height: 213.67px;
  left: 837.67px;
  top: 716.18px;
  border: 3px solid #edbdbd;
  margin-top: -247px;
}
.displaySquare3 {
  width: 23.3%;
  height: 213.67px;
  left: 837.67px;
  top: 716.18px;
  border: 3px solid #edbdbd;
  margin-top: -247px;
}
#root
  > div.mainContainer
  > div:nth-child(2)
  > div
  > div.dailyPulseContainer
  > div.dailyPulseLeftSection
  > div.mainMapsContainer
  > div.mapContainer
  > div.map
  > div
  > div
  > div
  > div:nth-child(2)
  > div:nth-child(2)
  > div
  > div:nth-child(4)
  > div:nth-child(2)
  > div
  > div
  > div {
  border-radius: 18px;
}
#root
  > div.mainContainer
  > div:nth-child(2)
  > div
  > div.dailyPulseContainer
  > div.dailyPulseLeftSection
  > div.mainMapsContainer
  > div.mapContainer
  > div.map
  > div
  > div
  > div
  > div:nth-child(2)
  > div:nth-child(1)
  > div:nth-child(4)
  > div
  > div
  > div
  > div {
  margin-top: 80px !important;
  /* font-family: 'Montserrat' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  text-align: center !important;
  color: #3b4a57 !important; */
}
.smilyFooter {
  color: #73808c;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 12px;
  padding-top: 28px;
  padding-left: 22px;
}
#root > div.mainContainer > div:nth-child(2) > div > div.dailyPulseContainer > div.dailyPulseLeftSection > div.mainMapsContainer > div.mapContainer > div.map > div > div > div > div:nth-child(2) > div:nth-child(2) > div > div:nth-child(4) > div > div > div > div.gm-style-iw.gm-style-iw-c > button {
  display: none !important;
}
#root > div.mainContainer > div:nth-child(2) > div > div.dailyPulseContainer > div.dailyPulseLeftSection > div.mainMapsContainer > div.mapContainer > div.map > div > div > div > div:nth-child(2) > div:nth-child(2) > div > div:nth-child(4) > div > div > div > div.gm-style-iw.gm-style-iw-c > div {
  overflow: hidden;
  padding-right: 12px;
  padding-left: 4px;
}
#root > div.mainContainer > div:nth-child(2) > div > div.dailyPulseContainer > div.dailyPulseLeftSection > div.mainMapsContainer > div.mapContainer > div.map > div > div > div > div:nth-child(2) > div:nth-child(1) > div:nth-child(4) > div > div > div > div {
  width: 56px !important;
  white-space: initial !important;
  text-align: center;
}