#badgeSelectionDialog .p-dialog-content {
  padding: 0%;
  /* width: 1005.3px;
    height: 819.28px; */
  border-radius: 16px !important;
  display: flex;
}
.badgeSelectionWrapper {
  display: flex;
  justify-content: space-between;
  /* height: 100%; */
}
.leftSideBadgeSelection {
  width: 562.82px;
  height: 100%;
  background-color: #fff;
}
.leftSideBadgeSelectionHeader {
  padding-left: 24px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 74px;
  color: #1e3264;
  background-color: #ffa894;
  height: 74px;
  width: 562.82px;
  display: flex;
  justify-content: space-between;
}
.leftSideBadgeSelectionContainer {
  padding-top: 32px;
}
.centerBadgeSelection {
  height: 819.34px !important;
  width: 16px !important;
  background: #f6f7f9 !important;
}

.rightSideBadgeSelection {
  width: 426.48px;
  background-color: #ffffff;
  overflow-y: hidden;
  overflow-x: hidden;
}
.rightSideBadgeSelectionHeader {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #3b4a57;
  background: #edbdbd;
  height: 74px;
  /* width: 426.48px; */
  width: fit-content;
  /* border-radius: 0px 16px 0px 0px; */
  /* position: fixed;
  z-index: 3; */
}
.check {
  height: 600.1px;
  width: 426.48px;
  overflow-y: auto;
}
#badgeSelectionTabInActive {
  padding-top: 2vh;
  color: #3b4a57;
  font-size: 16px;
  font-weight: 400;
  font-family: Montserrat;
}
#badgeSelectionTabActive {
  padding-top: 2vh;
  box-shadow: none;
  color: #3b4a57;
  font-size: 16px;
  font-weight: 600;
  font-family: Montserrat;
}
/* #badgeId-tab1 {
  padding: 30.5px;
} */
.badgeSelection-tabStyle {
  margin-left: 1vw;
  margin-right: 1vw;
  box-shadow: none !important;
  cursor: pointer;
}
.badgeSelectionSearchBar {
  width: 90%;
  /* height: 72px; */
  margin-top: 18px;
}
.badgeName {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  /* padding-left: 16px; */
}
.badgeDesc {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 154%;
  color: #3b4a57;
  /* padding-left: 16px; */
}
.badgeSelectionFooterBody {
  /*height value is changed from 8vh to 7.5vh*/
  height: 69.5px;
  /*bottom value is changed from 5.4vh to 6vh*/
  bottom: -0.0005vh;
  width: 426.48px;
  position: sticky;
  background-color: white;
  border-radius: 0px 0px 16px 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  /* margin-right: 20px !important; */
}
.badgeSelectionCancelButton {
  margin-right: 4%;
  border: none;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #0051c2;
  cursor: pointer;
  background-color: white;
}
.badgeSelectionNextButton {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2px;
  margin-right: 30px;
}
.tabHeading {
  /* padding-top: 34px !important; */
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #3b4a57;
}
.badgeSelectionText {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  align-items: center;
  color: #3b4a57;
  padding-left: 32px;
}
.badgeSelectionPara {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 13px;
  line-height: 154%;
  align-items: center;
  color: #3b4a57;
  padding-left: 32px;
  padding-right: 34.82px !important;
}
.rightSideBadgeSelectionContainer {
  /* height: 100%; */
  overflow-y: scroll;
}
#badgeSelectionDialog_content {
  overflow: hidden;
}
.badgeImage {
  /* padding-left: 16px; */
  margin: 0px 16px 0px 16px;
  width: 44px;
  height: 44px;
}
#tabStyle1 {
  display: flex;
  flex-direction: row;
  /* justify-content: space-around; */
  align-items: center;
  height: 74.3px;
  background-color: #edbdbd;
  margin-bottom: 0px;
}
#tabStyle1 > button:focus {
  box-shadow: none;
}
#newConfigurationDialog_content
  > div.newConfigurationContainer
  > div.newConfigCategoryContainer
  > div
  > view {
  justify-content: flex-start !important;
}

#newConfigurationDialog_content
  > div.newConfigurationContainer
  > div
  > view
  > span.newConfigDesc {
  margin-left: 19px !important;
}
#badgeId-tab1 > div:nth-child(1) > div:nth-child(2) > div > div > div > label {
  color: #c2c9d1;
}
