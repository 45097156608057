#addToTeamDialog_content {
  padding: 0;
  border-radius: 16px;
}

#addToTeamDialog {
  box-shadow: none;
}

.addToTeamHeader {
  background-color: #ffc226;
  height: 72px;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 18px;
  line-height: 72px;
  padding-left: 32px;
  color: #1e3264;
}

.addToTeamBody {
  font-family: Montserrat;
  font-size: 18px;
  line-height: 28px;
  color: #3b4a57;
  padding: 32px 32px 15px 32px;
}

.addToTeamFooterText {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #0051c2;
  padding-right: 5%;
  cursor: pointer;
}

.addToTeamFooter {
  height: calc(8vh + 1px);
  margin: 0 32px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #fff;
  z-index: 1;
}

#addToTeamButton > span {
  padding: 12px 24px;
}

#addToTeamDropdown > span {
  top: calc(50% - 0.6rem);
}

.addToTeamPeopleContainer {
  padding: 17px 0 0 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.addToTeamPeopleName {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1px;
  color: #1e3264;
  padding-left: 1rem;
}

#addToTeamDropdown + ul {
  max-height: 35vh;
}
