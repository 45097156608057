.PeopleDetailContainerTW {
  /* width: 50.3%; */
  /* background-color: #f6f7f9; */
  height: 100%;
  /* overflow-y: auto; */
  display: flex;
  justify-content: space-between;
}

.twoWayMessageLeftSection {
  display: flex;
  flex-direction: column;
  /* width: 62.7%; */
  width: 562.82px;
  height: 780.3px;
  background-color: #fff;
  /* overflow-y: auto; */
}

.twoWayMessageLeftContainer {
  position: absolute;
  width: 547px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffa894;
  height: 74px;
  border-radius: 16px 0px 0px 0px;
  /* border-top-right-radius: 16px; */
}

.twoWayMessageLeftHeading {
  padding-left: 32px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 32px;
  color: #1e3264;
}

.PeopleDetailsRightSectionExpandTW {
  width: 426.48px;
  background-color: #fff;
  /* overflow-x: hidden; */
  transition: width 0s ease-out;
  height: 820px;
}

.PeopleDetailsRightSectionCloseTW {
  position: relative;
  width: 0vw;
  background-color: #fff;
  /* overflow: hidden; */
  transition: width 0.2s ease;
  /* height: 500px; */
}

#people-detail .p-dialog-header {
  display: none !important;
}
#people-detail [role='tablist'] {
  height: 74px;
  margin-bottom: 0 !important;
}
#peopledetail-avatar {
  width: 82px;
  height: 82px;
  background-color: white;
  border-radius: 100px;
  position: relative;
  top: 30px;
  border: 6px solid #ffa894;
  right: 39px;
  box-shadow: -2px -5px 5px rgba(27, 31, 35, 0.05);
}
.flexItems {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.leftText {
  width: 240px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20.2px;
  color: #3b4a57;
}

.rightText {
  width: 142px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #3b4a57;
}

.informationText1 {
  margin-top: 20px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  width: auto;
}
.informationText01 {
  margin-top: 24px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
.rightText2 {
  margin-left: 120px;
  margin-top: 20px;
  font-family: 'Montserrat';
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
}
.rightText3 {
  margin-left: 29px;
  margin-top: 20px;
  font-family: 'Montserrat';
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
}
#TeamDropdown + ul {
  /* overflow-y: auto; */
  height: auto;
  max-height: 20vh;
  margin-top: 10px;
}
#twoWayEditButton {
  cursor: pointer;
  background-color: #ffffff;
  color: #0051c2;
  border: none;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Montserrat';
  margin-right: 10px;
}
#twoWay-nextButton > span {
  padding: 12px 24px;
  /* height: 40%; */
}
.twoWayAvatarContainer {
  position: relative;
  top: 30px;
  right: 39px;
  box-shadow: -2px -5px 5px rgba(27, 31, 35, 0.05);
  border-radius: 100px;
}
.twoWayButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  /* margin-right: 32px; */
  margin-top: 20px;
  margin-bottom: 10px;
}
.editButtonTwoWay {
  border: none;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Montserrat';
  margin-right: 10px;
}
.recipientsScreenFooterTwoWay,
.templatesScreenFooterTwoWay,
.graphicScreenFooterTwoWay {
  height: 69px;
  position: absolute !important;
  bottom: 19px !important;
  width: 425px;
  background-color: white;
}
.twoWayRecipientsScreenHeaderRight {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #3b4a57;
  margin-left: 32px;
  margin-top: 37px;
}
.twoWayrecipientsScreenBody,
.twoWaytemplatesScreenBody,
.twoWaygraphicScreenSubHeader {
  margin-left: 32px;
  margin-top: 25px;
}
.rightMsgDialogBodyInputTW {
  margin-top: 20px;
  width: 370px;
  margin-left: 32px;
}
.rightMsgDialogBodyContentTW {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #3b4a57;
  margin-left: 32px;
  margin-top: 30px;
}
.rightMsgDialogBodyInputTextArea {
  width: 370px !important;
  margin-top: 20px !important;
  margin-left: 32px !important;
  background-color: #f6f7f9 !important;
  box-shadow: inset 0px -2px 0px #c2c9d1 !important;
  border-radius: 16px !important;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  font-family: 'Montserrat' !important;
  color: #3b4a57 !important;
  font-weight: 600 !important;
  font-size: 1rem !important;
  padding: 25px !important;
}
.TWmsgTextAreaCount {
  margin-top: 5px;
  margin-left: 32px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #262c30;
  /* float: right; */
  margin-right: 31px;
  padding-left: 328px;
  position: absolute;
}
.TWmsgCheckBoxAndLabelWrapper {
  display: flex;
  margin-top: 20px;
  margin-left: 32px;
  align-items: center;
}
.recipientsFooterLineTW,
.templatesFooterLineTW,
.graphicFooterLineTW {
  border: 1px solid #c2c9d1;
  margin-left: 32px;
  margin-right: 25px;
  margin-top: 23px;
}
.leftMsgDialogBodyFooterLineWrapperTW {
  display: flex;
  height: 65px;
  align-items: center;
  justify-content: space-between;
  padding-left: 25px;
}
.sendButtonMsgTW {
  height: 37px;
  margin-right: 20px;
  margin-left: 10px;
}
.templatesScreenHeaderTW,
.graphicScreenHeaderTW {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #3b4a57;
  margin-left: 32px;
  margin-top: 34px;
}
.templatesScreenBodyHeaderTW {
  display: flex;
  align-items: center;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  align-items: center;
  color: #29545c;
  gap: 10px;
  margin-bottom: 12px;
}
.templatesScreenBodyTW {
  margin-right: 32px;
  margin-left: 37px;
  margin-top: 8px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #29545c;
  height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  margin-bottom: 10px;
}
.templatesScreenFooterButtonWrapper {
  display: flex;
  height: 69px;
  align-items: center;
  justify-content: space-between;
}
.graphicScreenHeaderBodyTW {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #29545c;
  margin-left: 32px;
  margin-top: 20px;
  padding-right: 30px;
}

.cancelButtonMsg,
.templatesDeleteEnableButton,
.templatesDeleteDisableButton {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #0051c2;
  background-color: white;
  border: none;
  cursor: pointer;
}

.templatesDeleteDisableButton {
  /* cursor: none; */
  color: #a7a7a7;
  pointer-events: none;
  margin-left: 32px;
}

.msgTabSelection {
  display: flex;
  align-items: center;
  height: 71px;
  background-color: #edbdbd;
}

/* body > div > div:nth-child(3) > div > div > div > div > div > div.rightMsgDialogBody > div > div{
  position: sticky;
  top: 0;
  width: 100%;
} */
/* body > div.sc-m9cfk7-1.bHBLUE > div:nth-child(3) > div > div > div > div > div > div.rightMsgDialogBody > div > div.sc-1vf37w-1.cIqkep{
  z-index: 400;
} */

.twoWaygraphicScreenSubHeader {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #29545c;
}

.defaultGraphicBodyTW {
  display: flex;
  margin-left: 32px;
  margin-top: 25px;
  cursor: pointer;
}
.graphicCollectionInnerBodyTW {
  display: flex;
  margin-left: 15px;
  /* margin-top: 25px; */
  cursor: pointer;
  margin-bottom: 10px;
}
.graphicTickTW {
  height: 10px;
  position: absolute;
  margin-left: 56px;
}

.graphicCollectionHeader {
  margin-left: 32px;
  margin-top: 25px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #29545c;
}

.graphicCollectionBody {
  display: flex;
  margin-top: 25px;
  margin-left: 20px;
  max-width: 400px;
  flex-wrap: wrap;
}

.templatesDownArrow {
  cursor: pointer;
}
.templatesScreenFooterButtonWrapper {
  display: flex;
  height: 69px;
  align-items: center;
  justify-content: space-between;
}
.graphicScreenFooterButtonWrapper {
  display: flex;
  height: 69px;
  align-items: center;
  justify-content: flex-end;
  margin-right: 25px;
}
.templatesUseButton {
  height: 37px;
  margin-right: 25px;
}
.graphicUseButton {
  height: 37px;
}

.templatesDeleteEnableButton {
  margin-left: 32px;
  cursor: pointer;
}
.msgTab1,
.msgTab2,
.msgTab3 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #3b4a57;
  border: none !important;
  box-shadow: none !important;
  cursor: pointer;
  margin-left: 25px !important;
}

body > div > div:nth-child(3) > div > div {
  padding: 0px 0px 0px 0px;
}

body > div > div:nth-child(3) > div > div > div {
  padding: 0px 0px !important;
  margin: 0px 0px !important;
}

body > div > div:nth-child(3) > div > div > div > div {
  margin-bottom: 0px !important;
}

body > div > div:nth-child(3) > div > div > div {
  overflow: hidden !important;
}

input[type='radio']:after {
  width: 16px;
  height: 16px;
  border-radius: 15px;
  margin-top: -5px;
  position: relative;
  background-color: white;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 3px solid #73808c;
  cursor: pointer;
}

input[type='radio']:checked:after {
  width: 8px;
  height: 8px;
  border-radius: 15px;
  margin-left: -1.1px;
  position: relative;
  /* background-color: #ffa500; */
  content: '';
  display: inline-block;
  visibility: visible;
  border: 8px solid #0051c2;
  cursor: pointer;
  /* margin-left: 30px; */
}
@media (min-width: 48em) {
  body > div > div:nth-child(3) > div {
    max-width: 100rem;
  }
}
.PeopleDetailsRightSectionTW {
  width: 0vw;
  background-color: #fff;
  /* overflow: hidden; */
  /* height: 500px; */
}
#people-detail_content {
  padding: 0px !important;
  border-radius: 16px;
  overflow-y: hidden;
  overflow-x: hidden;
}
.messageHeading {
  width: 347px;
  left: 0px;
  top: 87px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  color: #3b4a57;
}
.messageContent {
  width: 304px;
  line-break: anywhere;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 154%;
  display: flex;
  align-items: flex-start;
  color: #29545c;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  flex-direction: column;
}
.messageContentFirstTime {
  width: 471px;
  line-break: anywhere;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 154%;
  display: flex;
  align-items: flex-start;
  color: #29545c;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  flex-direction: column;
}
.svMessage {
  display: flex;
  align-items: flex-end;
}
.dayDateTime {
  width: 333px;
  /* height: 10px; */
  /* padding-bottom: 10px; */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  color: #73808c;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
.mainDiv {
  display: flex;
  margin-right: 6.5%;
  padding-top: 3.5%;
}
.mainDivRecieved {
  display: flex;
  flex-direction: row-reverse;
  padding-right: 35%;
  padding-top: 3%;
}
.deleteNote {
  display: flex;
  padding: 30px;
  height: 10px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  text-align: center;
  color: #73808c;
  padding-left: 80px;
}
.primeFirstAvatar1 {
  border: 3px solid white !important;
  outline: 3px solid #a4dedf !important;
}
.messagesBody {
  width: 347px;
  /* height: 80px; */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 154%;
  color: #3b4a57;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.newIndication {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  letter-spacing: 0.1px;
  color: #008a00;
  margin-left: 200px;
  margin-top: -8px;
}
.deleteMessageNotify {
  width: 324.32px;
  height: 10px;
  left: 119.25px;
  top: 123.86px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  text-align: center;
  color: #73808c;
  
}
.linesCSS {
  margin-top: 4px;
  width: 70px;
  height: 0px;
  border: 0.5px solid #c2c9d1;
}
.useAsATemplate1to1{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #0051C2;
  background-color: white;
  border: none;
  cursor: pointer;
}
.useAsATemplate1to1,.useAsATemplateDisable1to1{
  /* margin-right: 25px; */
  white-space: nowrap;
  margin-bottom: 5px;
}
.useAsATemplateDisable1to1{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #A7A7A7;
  background-color: white;
  border: none;
  cursor:pointer;
  pointer-events: none;
}
#twoWay-nextButton{
  margin-right: 26px !important;
}