#editMultiContactDialog_content {
  padding: 0;
  border-radius: 16px;
  overflow-y: hidden;
}
#editMultiContactUpdateDialog_content {
  padding: 0;
  border-radius: 16px;
  /* overflow-y: hidden; */
}
.multiSelectWholeContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.multiSelectLeftContainer {
  width: 55%;
  height: 820px;
  overflow: auto;
}
.multiSelectRightContainer {
  width: 44%;
  height: 820px;
  overflow: auto;
}
.multiSelectLeftHeaderContainer {
  background-color: #ffa894;
  height: 70px;
  display: flex;
  align-items: center;
}
.multiSelectRightHeaderContainer {
  background-color: #edbdbd;
  height: 70px;
  display: flex;
  align-items: center;
}
.multiSelectLeftHeader {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 128%;
  color: #1e3264;
  padding-left: 32px;
}
.multiSelectRightHeader {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #3b4a57;
  padding-left: 32px;
}
.multiSelectBodyContainer {
  height: 69vh;
  overflow: auto;
}
.multiSelectBodyHeader {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  display: flex;
  align-items: center;
  color: #3b4a57;
  padding-top: 35px;
  padding-left: 32px;
}
.multiSelectBody {
  /* padding-bottom: 51vh; */
  padding-top: 35px;
}
.multiSelectCircle,
.multiSelectCircle2 {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
.circleNameStatusContainer {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  padding-left: 32px;
  padding-right: 32px;
}
.multiSelectName,
.multiSelectName2 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #000000;

  margin-left: 16px;
  width: 320px;
}
.multiSelectStatusEditing,
.multiSelectStatusEdited,
.multiSelectStatusEdited2,
.multiSelectStatusToBeEdited {
  width: 150px;
  text-align: right;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}
.multiSelectStatusEditing {
  color: #0051c2;
}
.multiSelectStatusEdited {
  color: #008a00;
}
.multiSelectStatusToBeEdited {
  color: #73808c;
}

.rightSectionNameHeading,
.rightSectionNumberHeading,
.rightSectionRoleHeading,
.rightSectionColorHeading {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  color: #3b4a57;
  padding-left: 34px;
  padding-top: 30px;
}

.rightSectionNameInputContainer,
.rightSectionNumberInputContainer,
.rightSectionRoleInputContainer {
  padding-top: 15px;
  padding-left: 32px;
  padding-right: 32px;
}
.multiEditContactColorContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  padding-left: 31px;
  padding-right: 31px;
  height: 60px;
  margin-top: 10px;
  margin-bottom: 80px;
}
.rightSectionWholeContainer {
  height: 75vh;
  overflow: auto;
}
.multiEditContactRightSideFooter {
  /* display: flex;
  align-items: center; */
  position: absolute;
  bottom: 0;
  width: 44%;
  background-color: white;
  height: 70px;
  border-bottom-right-radius: 16px;
}
.multiEditContactRightSideFooterButtonsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 65px;
  gap: 20px;
}
.multiEditContactRightSideNextButton {
  height: 45px;
  margin-right: 30px;
}

.multiEditContactRightSideCancelButton,
.multiEditContactRightSideBackButton {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #0051c2;
  background-color: white;
  border: none;
  cursor: pointer;
}
.multiUpdateDialogHeaderWrapper {
  display: flex;
  height: 70px;
  align-items: center;
  background-color: #ffa894;
  padding-left: 32px;
}
.multiUpdateDialogHeader {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 128%;
  color: #1e3264;
}
.multiUpdateDialogBodyWrapper {
  /* border: 2px solid black; */
  padding: 35px 0px 70px 32px;
}
.multiUpdateDialogBodyHeader {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  color: #3b4a57;
}
.multiUpdateDialogBody {
  /* border: 2px solid red; */
  margin-right: 32px;
  margin-top: 34px;
}
.circleNameStatusContainer2 {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}
.editMultiContactUpdateDialogFooter {
  background-color: white;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 70px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}
.UpdateDialogFooterButtonsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  /* background-color: red; */
  padding-left: 30px;
  padding-right: 30px;
}
.UpdateDialogFooterEditUpdate {
  display: flex;
  gap: 20px;
  margin-bottom: 3px;
}
.UpdateDialogFooterUpdateButton {
  height: 50px;
}
.UpdateDialogFooterEditButton,
.UpdateDialogFooterCancelButton {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #0051c2;
  border: none;
  background-color: white;
  cursor: pointer;
}
.UpdateDialogFooterCancelButton {
  margin-bottom: 10px;
  margin-left: 5px;
}
.multiSelectStatusEdited2 {
  color: #008a00;
}
.multiSelectMiddleContainer {
  background-color: #f6f7f9;
  width: 1%;
}
