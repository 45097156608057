.peopleScreenHeaderContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffa894;
  height: 74px;
  padding: 0 24px;
  padding-right: 35px;
}

.peopleHeader {
  font-family: 'Montserrat';
  font-weight: bold;
  font-size: 25px;
  line-height: 32px;
  color: #1e3264;
}

.peopleHeaderText {
  font-family: 'Montserrat';
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1px;
  color: #1e3264;
  padding-right: 10%;
}
.peopleHeaderTextPS {
  font-family: 'Montserrat';
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1px;
  color: #1e3264;
  padding-left: 67%;
  /* padding-right: 10%; */
}

.peopleContainer {
  display: flex;
  justify-content: space-between;
  height: 100%;
}
.peopleLeftSectionRoles {
  width: 100%;
  background-color: #fff;
  overflow-y: hidden;
  background: url('../../images/PECfox.png') no-repeat right top 135px , #fff;
  background-size: 700px;
  background-position-x: 95%;
}
.peopleLeftSection {
  width: 66.7%;
  background-color: #fff;
  overflow-y: hidden;
  /* padding-bottom: 8vh; */
  background: url('../../images/fox.png') no-repeat right top 135px, #fff;
  /* background-size: cover; */
  /* background-size: 500px 500px; */
  /* background: url('../../images/fox.png') no-repeat right top 135px; */
}
.homepageFooterContainerRoles {
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 8px;
  position: fixed;
  bottom: 0;
  width: 82%;
  background-color: #fff;
  justify-content: center;
  z-index: 1;
}
.peopleRightSection {
  width: 33.3%;
  background-color: #fff;
  overflow-y: scroll;
}

.peopleTableHeader {
  display: flex;
  align-items: center;
  margin-left: 24px;
}

.peopleName {
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.peopleDetails,
.peopleDetailsPEC,
.peopleDetailsPECEmployee,
.peopleDetailsPECLevel {
  font-family: 'Montserrat';
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: #3b4a57;
}

.peopleScreenPagination {
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #73808c;
  padding: 0px 50px;
}

.peopleFooterTextEnable {
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  padding: 0 24px;
  color: #0051c2;
  cursor: pointer;
}

.peopleFooterTextDisable {
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  padding: 0 24px;
  color: #a7a7a7;
}
.avatarhover:hover {
  cursor: pointer;
}
.avatarhover {
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2));
}

#deletePeople {
  background-color: #bd2841;
}

#deletePeople::before {
  background-color: #bd2841;
}

#deletePeople > span {
  padding: 12px 24px;
}

.peopleTableHeading,
.levelTableHeading,
.exceptionalRewardsTableHeading {
  display: flex;
  align-items: center;
  /* margin-left: 24px; */
}
.exceptionalRewardsTableHeadingPS {
  display: flex;
  align-items: center;
  width:50%;
  /* padding-left: '20%'; */
  /* margin-left: '33%'; */
  /* margin-left: 24px; */
}
.peopleTableHeading {
  /* background-color: blue; */
  margin-left: 24px;
  width: 30%;
}
.levelTableHeading {
  /* background-color: green; */
  width: 20%;
}
.exceptionalRewardsTableHeading {
  /* background-color: red; */
  width: 50%;
}
.peopleDetailsPEC {
  width: 20%;
  margin-top: 10px;
}
.peopleDetailsPECEmployee{
  width: 20.5%;
  margin-top: 10px;
}
.peopleDetailsPECLevel{
  width: 19%;
  margin-top: 10px;
}
.peopleBadgesPEC {
  width: 50%;
  margin-top: 15px;
  display: flex;
}
/* #root
  > div.mainContainer
  > div:nth-child(2)
  > div
  > div
  > div:nth-child(4)
  > div
  > div:nth-child(1)
  > div.peopleBadgesPEC
  > div:hover {
  border: 3px solid white;
  outline: 3px solid #edbdbd;
} */
/* #root
  > div.mainContainer
  > div:nth-child(2)
  > div
  > div
  > div:nth-child(4)
  > div
  > div:nth-child(1)
  > div.peopleBadgesPEC
  > div {
  margin-left: 10px;
} */
#root
  > div.mainContainer
  > div
  > div
  > div
  > div
  > div
  > div
  > div.peopleBadgesPEC
  > div
  > div {
  margin-left: 10px;
}

#root
  > div.mainContainer
  > div
  > div
  > div
  > div
  > div
  > div
  > div.peopleBadgesPEC
  > div
  > div:hover {
  border: 3px solid white;
  outline: 3px solid #edbdbd;
}
.toolTipHeader {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  /* identical to box height, or 20px */
  color: #000000;
  margin-top: 16px;
  margin-left: 23px;
  margin-right: 23px;
}
.toolTipBody {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 154%;
  /* identical to box height, or 20px */
  margin-top: 5px;
  margin-left: 23px;
  margin-bottom: 16px;
  color: #3b4a57;
}
.MuiTooltip-tooltip {
  background-color: #ffffff !important;
  box-shadow: 0px 15px 22px rgba(0, 0, 0, 0.06), 0px 3px 8px rgba(0, 0, 0, 0.08),
    0px 1px 1px rgba(0, 0, 0, 0.08), 0px 3px 1px rgba(0, 0, 0, 0.04) !important;
  border-radius: 18px !important;
  width: 265px !important;
}
.peopleAvatarWrapper {
  margin-left: 10px;
}
.disablePreNextArrow {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.msgPeopleScreenButton{
  color: #0051C2;
  height: 16px;
  background-color: white;
  margin-right: 34px;
  border: none;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
}
#peoplePostiTooltip{
  background-color: white !important;
  box-shadow: 0px 15px 22px rgba(0, 0, 0, 0.06), 0px 3px 8px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.08), 0px 3px 1px rgba(0, 0, 0, 0.04);
  border-radius: 18px;
  width: 265px !important;
  min-height: 72px !important;
  padding-left: 24px !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}
.toolTipHeaderPeople{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  color: #000000;
}
.toolTipBodyPeople{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 154%;
  color: #3B4A57;
  margin-top: 5px;
}
#peoplePostiTooltip::before{
  display: none !important;
}
#root > div.mainContainer > div:nth-child(2) > div > div > div.peopleScreenHeaderContainer > div:nth-child(2) > div > div{
  min-height: 0rem !important;
}