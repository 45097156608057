.addNewContactWrapper {
  display: flex;
  justify-content: space-between;
  height: 100%;
}
#addNewContactDialog .p-dialog-content {
  padding: 0px;
  border-radius: 16px;
}
::placeholder {
  font-family: Montserrat !important;
  font-style: normal !important;
  font-weight: 600 !important;
  color: #c2c9d1 !important;
}
.leftSideAddNewContact {
  width: 35.5vw;
  height: 100%;
  background-color: #fff;
}
.centerAddNewContact {
  height: 90vh;
  width: 1vw;
  background: #f6f7f9;
}
.rightSideAddNewContact {
  width: 26.5vw;
  height: 80vh;
  background-color: #fff;
  overflow-y: scroll;
}
.leftSideAddNewContactHeader {
  padding-left: 24px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 74px;
  color: #1e3264;
  background-color: #ffa894;
  height: 74px;
  display: flex;
  justify-content: space-between;
}
.p .containerHeader {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  display: flex;
  align-items: center;
  color: #3b4a57;
}
.rightSideAddNewContactHeader {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  color: #1e3264;
  background: #edbdbd;
  height: 74px;
  /* border-radius: 0px 16px 0px 0px; */
}
.vipContactsText {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  align-items: center;
  color: #3b4a57;
  padding-left: 32px;
  margin-top: 4vh;
}
.para {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 13px;
  line-height: 154%;
  align-items: center;
  color: #3b4a57;
  padding-left: 32px;
  padding-right: 30px;
}
.leftSideAddNewContactFooter {
  height: 8vh;
  bottom: 5vh;
  width: 35vw;
  position: fixed;
  background-color: white;
  border-radius: 16px;
}
.rightSideAddNewContactFooter {
  height: 8vh;
  bottom: 5vh;
  width: 26.5vw;
  position: fixed;
  background-color: white;
  border-radius: 0px 0px 16px 0px;
}

.rightSideAddNewContactFooterButton {
  padding-top: 1vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 11px;
}
.rightSideNextButton {
  padding-right: 1.48vw;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.rightSideAddNewContactButton {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #0051c2;
  padding-right: 5%;
  cursor: pointer;
}
.p-dialog-content {
  flex-grow: 1;
}
.AddNewContactName {
  width: 100%;
}
.nameText {
  padding-left: 2vw;
  padding-top: 5vh;
  height: 20px;
  left: 0px;
  right: 15.93px;
  top: 18px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  display: flex;
  align-items: center;
  color: #3b4a57;
}
.enterBlock {
  margin-left: 2vw;
  margin-top: 3vh;
  position: static;
  width: 22vw;
}
.listText {
  padding-left: 2vw;
  width: 365.31px;
  height: 20px;
  font-family: Montserrat;
  font-size: 13px;
  line-height: 154%;
  color: #3b4a57;
}
.rightSideButton > button > span {
  padding: 0.5rem 1.5rem;
}
#nameTabInActive {
  padding-top: 4vh;
}
#nameTabActive {
  padding-top: 4vh;
}
.addNew-tabStyle {
  margin-left: 2vw;
  box-shadow: none !important;
  cursor: pointer;
}

#addNewContactDialog_content
  > div
  > div.rightSideAddNewContact
  > div.rightSideAddNewContactHeader
  > div
  > div.sc-1vf37w-2.kYTJTS {
  bottom: -12.5px;
  display: inline-block;
}
.listOfAddedContacts {
  padding-left: 2vw;
  padding-top: 2vh;
  display: flex;
  align-items: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 3vh;
  color: #000000;
}
.colourContentContacts {
  padding-left: 2.5vw;
  display: flex;
  align-items: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 8vh;
  color: #000000;
  padding-top: 2vh;
  height: 6vh;
}
/* .colorContentContainer {
  height: 62vh;
} */
.colourHeader {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  color: #3b4a57;
  padding-left: 2.5vw;
  padding-top: 3vh;
}
.colourContentFooter {
  height: 19vh;
  bottom: 5vh;
  width: 26.5vw;
  position: fixed;
  background-color: white;
  border-radius: 0px 0px 16px 0px;
}
.colorIndicationText {
  margin-left: 2.5vw;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  color: #3b4a57;
}
.colorIndication {
  margin-top: 1vh;
  width: 22vw;
  display: flex;
  justify-content: space-around;
  margin-left: 2vw;
}
.deselectButtonTextEnable {
  margin-right: 5.4vw;
  margin-top: 1%;
  margin-bottom: 1%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
  color: #0051c2;
  border: none;
}
.deselectButtonTextEnable1 {
  margin-right: 10vw;
  margin-top: 1%;
  margin-bottom: 1%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
  color: #0051c2;
  border: none;
}
.deselectButtonTextDisable {
  margin-right: 5.4vw;
  margin-top: 1%;
  margin-bottom: 1%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #a7a7a7;
}
.deselectButtonTextDisable1 {
  margin-right: 10vw;
  margin-top: 1%;
  margin-bottom: 1%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #a7a7a7;
}
.orderContentContainer {
  height: 70vh;
}
.orderContentContacts {
  display: flex;
  align-items: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 8vh;
  color: #000000;
  height: 6vh;
  flex-direction: row;
  padding: 2px 16px 2px 6px;
  background: #f6f7f9;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 16px;
  margin-left: 2vw;
  width: 22vw;
}
.contactsOrder {
  margin-top: 2vh;
}
#addNewContactDialog_content
  > div
  > div.rightSideAddNewContact
  > div.rightSideAddNewContactHeader
  > div
  > div.sc-1vf37w-2.cnBWiI {
  bottom: -12.5px;
}
#id-tab1
  > div:nth-child(2)
  > div.rightSideAddNewContactFooter
  > div
  > div.rightSideNextButton
  > button
  > span
  > span {
  line-height: 0.2px;
}
#nameTabActiveNew {
  box-shadow: none;
  color: #3b4a57;
  font-size: 16px;
  font-weight: 600;
  font-family: Montserrat;
  padding-top: 3vh;
}
#nameTabInActiveNew {
  color: #3b4a57;
  font-size: 16px;
  font-weight: 400;
  font-family: Montserrat;
  padding-top: 3vh;
}
