.organizedBadgeWrapper{
    padding-top: 100px;
    padding-left: 26px;
    padding-right: 26px;
}
.organizedBadgeRuleHeading,.organizedBadgeInformationHeading,.organizedBadgeVariablesHeading,.organizedBadgeStatisticHeading{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 125%;
    color: #3B4A57;
}
.organizedBadgeRuleBody{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 125%;
    color: #000000;
    padding-top: 18px;
}
.organizedBadgeInformationHeading,.organizedBadgeVariablesHeading,.organizedBadgeStatisticHeading{
    padding-top: 20px;
}
.organizedBadgeVariablesBody,.organizedBadgeStatsBody,.organizedBadgeInformationBody{
    padding-top: 18px;
}
.organizedBadgeVariablesDayBody,.organizedBadgeStatsDayBody,.organizedBadgeInformationDayBody{
    display: flex;
}
.organizedBadgeVariablesDay1stColumn,.organizedBadgeStatsDay1stColumn,.organizedBadgeInformationDay1stColumn{
    width: 185px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 154%;
    color: #3B4A57;
}
.organizedBadgeVariablesDay2ndColumn,.organizedBadgeStatsDay2ndColumn,.organizedBadgeInformationDay2ndColumn{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 154%;
    color: #3B4A57;
    width: 360px;
}
.astrikStyle{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;
    color: #73808C;
    padding-top: 10px;
    display: flex;
}
.lineAboveText,.lineAboveTextVariable{
    text-decoration: line-through;
}
.lineAboveTextVariable{
    margin-left: 5px;
}
.filterFooterBadges {
    height: 8.3vh;
    background-color: white;
    position: fixed;
    bottom: 0;
    width: 27.5%;
    padding-left: 24px;
    padding-right: 24px;
    /* padding-bottom: 20px; */
  }
  .filterFooterBodyBadges {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 8vh;
  }
  .badgesFilterApplyButton {
    height: 39px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2px;
    margin-right: 10px;
    font-size: 16px;
  }
  .organizedBadgeStatsBody{
    margin-bottom: 9vh;
  }
  