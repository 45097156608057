.wholeBadgesContainer {
  display: flex;
  justify-content: space-between;
  /* background-color: red; */
  height: 100%;
}
.badgesLeftSection {
  width: 66.7%;
  background-color: white;
  overflow-y: auto;
}
.BadgesRightSection {
  width: 33.3%;
  background-color: white;
  overflow-y: auto;
}
.badgesLeftSectionHeaderContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #F7CC7A;
  height: 74px;
  padding: 0 24px;
  z-index: 3;
  position: fixed;
  width: 55.86%;
}
.badgesHeader {
  font-family: 'Montserrat';
  font-weight: bold;
  font-size: 25px;
  line-height: 32px;
  color: #1e3264;
}
#headerButtons > div.p-button.p-component {
  height: 44px;
}
#headerButtons > div.p-button.p-component.p-highlight {
  background-color: #29545c;
  color: white;
}
#headerButtons > div.p-button.p-component.p-highlight[aria-label='Locations'],
#headerButtons > div:nth-child(2) {
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
}
#headerButtons > div.p-button.p-component.p-highlight[aria-label='Badges'],
#headerButtons > div:nth-child(1) {
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
}
.badgesTableContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
}
.tableHeadingsBadges {
  display: flex;
  padding-left: 25px;
  padding-right: 25px;
  background-color: white;
  position: fixed;
  z-index: 3;
  width: 55.86%;
  margin-top: 74px;
}
.badgesScreenColumn{
  font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    letter-spacing: 0.1px;
    color: #1e3264;
    /* border: 1px solid black; */
    word-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.badgesHeadingBadges,
  .daysHeadingBadges,
  .slackHeadingBadges,
  .targetHeadingBadges,
  .pointsHeadingBadges,
  .startHeadingBadges,
  .endHeading,
  .eligibilityHeading,
  .locationsHeadingBadges {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    letter-spacing: 0.1px;
    color: #1e3264;
    /* border: 1px solid black; */
    word-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .badgesHeadingDataBadges,
  .daysHeadingDataBadges,
  .slackHeadingDataBadges,
  .targetHeadingDataBadges,
  .pointsHeadingDataBadges,
  .startHeadingDataBadges,
  .endHeadingData,
  .eligibilityHeadingData,
  .locationsHeadingDataBadges {
    /* display: flex; */
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1px;
    color: #1e3264;
    word-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 50px;
    margin-top: 18px;
  }
  .badgesHeadingBadges {
    width: 25%;
  }
  .badgesHeadingDataBadges {
    width: 25%;
  }
  .daysHeadingBadges,
  .slackHeadingBadges {
    width: 7%;
  }
  .targetHeadingBadges,.targetHeadingDataBadges{
    width: 10%;
  }
.pointsHeadingBadges,.pointsHeadingDataBadges{
    width: 9%;
}
.daysHeadingDataBadges,
  .slackHeadingDataBadges 
  {
    width: 7%;
  }
  .startHeadingBadges,
  .endHeading {
    width: 15%;
  }
  .startHeadingDataBadges,
  .endHeadingData {
    width: 15%;
  }
  .eligibilityHeading {
    width: 9%;
  }
  .eligibilityHeadingData {
    width: 9%;
  }
  .locationsHeadingBadges {
    width: 27%;
  }
  .locationsHeadingDataBadges {
    width: 27%;
  }
  .tableBodyBadges {
    display: flex;
    flex-direction: column;
    padding-left: 25px;
    padding-right: 25px;
    margin-bottom: 10vh;
    margin-top: -10px;
  }
  .badgesHeadingDataBadges {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #3b4a57;
  }
  .uniqueBadgeHeadingBadges,
  .uniqueBadgeHeading2Badges {
    display: flex;
    background-color: #f6f7f9;
    height: 33px;
    align-items: center;
    margin-bottom: 3px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #000000;
    justify-content: space-between;
    padding: 0 1% 0 1%;
    cursor: pointer;
    /* margin-top: 130px; */
  }
  .uniqueBadgeHeading2Badges {
    border-top: 1px solid #262c30;
    z-index: 1;
  }
  .ellipseImage {
    margin-right: 5%;
  }
  .uniqueBadgeHeadingBadges {
    z-index: 1;
    position: relative;
    margin-top: 0px;
  }
  .configurationFooter {
    height: 8.3vh;
    bottom: 0;
    width: 55.5%;
    position: fixed;
    background-color: white;
    z-index: 2;
  }
  .configurationFooterLine {
    border: 1px solid #c2c9d1;
    margin-left: 2%;
    margin-right: 1%;
    margin-top: 0px;
    background-color: #c2c9d1;
  }
  .configurationFooterButtonContainer {
    /* background-color: red; */
    height: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 2%;
    /* background-color: red; */
    padding-right: 1%;
  }
  .newConfigurationButton,
  .newConfigurationEditButton,
  .newConfigurationEditButtonDisable {
    border: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #0051c2;
    cursor: pointer;
    background-color: white;
  }
  .efficiencyTargetButton {
    white-space: nowrap;
    height: 39px;
    margin-top: 5px !important;
    font-size: '16px',
  }
  .newConfigurationEditButton,
  .newConfigurationEditButtonDisable {
    margin-right: 50px;
  }
  .newConfigurationEditButtonDisable {
    color: #a7a7a7;
    pointer-events: pointer;
  }
  .newConfigurationDeleteButton {
    border: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #0051c2;
    cursor: pointer;
    background-color: white;
    margin-left: 4vh;
    margin-top: 8px;
  }
  .newConfigurationDeleteButtonDisable {
    border: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #a7a7a7;
    cursor: pointer;
    background-color: white;
    margin-left: 4vh;
    cursor: pointer;
    margin-top: 8px;
  }
  .checkboxImgBadgeDataContainer {
    word-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: -4px;
    /* display: flex;
    align-items: center; */
  }
  .imgAndBadgeDataContainer{
    display: flex;
    width: 25%;
    align-items: center;
    gap:15px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    color: #3B4A57;
  }
  .badgesHeadingDataBadges {
    margin-top: 14px;
  }
  .configurationBadgesCheckbox,
  .configurationLocationCheckbox {
    margin-left: 3px;
  }
  #deleteConfigurationDialog_content,
  #deleteConfigurationInformationDialog_content {
    padding: 0;
    border-radius: 16px;
  }
  #deleteConfigurationDialog,
  #deleteConfigurationInformationDialog {
    box-shadow: none;
  }
  .configDeleteDialogHeader {
    display: flex;
    align-items: center;
    background: #ffc226;
    border-radius: 16px 16px 0px 0px;
    height: 72px;
    padding-left: 32px;
  }
  .configDeleteDialogHeading {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 155.6%;
    color: #1e3264;
  }
  .configDeleteDialogBody {
    height: 160px;
    padding: 31px 76px 44px 32px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 155.6%;
    color: #3b4a57;
  }
  .configDeleteDialogFooter {
    height: 71px;
    border-top: 1px solid #c2c9d1;
    margin-left: 32px;
    margin-right: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 24px;
  }
  .configDeleteDialogFooterDeleteButton,
  .configDeleteDialogFooterDeleteButton::before {
    background-color: #bd2841 !important;
    height: 50px;
  }
  .configDeleteDialogFooterCancelButton {
    border: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #0051c2;
    cursor: pointer;
    background-color: white;
  }
  .deleteConfigurationInformationDialogHeader {
    display: flex;
    align-items: center;
    height: 71px;
    background-color: #a4dedf;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 155.6%;
    /* identical to box height, or 28px */
    color: #1e3264;
    padding-left: 32px;
  }
  .deleteConfigurationInformationDialogBody {
    height: 260px;
    padding-left: 32px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 155.6%;
    color: #3b4a57;
    padding-top: 32px;
    padding-right: 30px;
  }
  .deleteConfigurationInformationDialogFooter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    height: 72px;
    width: 413px;
    border-top: 1px solid #c2c9d1;
  }
  .deleteInformationDialogFooterOkButton {
    height: 50px;
  }
  .onlyBadgeData {
    display: none !important;
  }
  .badgesFooterLineWholeContainer {
    height: 8vh;
    margin-top: -10px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .badgesFooterLineLeftContainer {
    height: 100%;
    display: flex;
    align-items: center;
  }
  .badgesFooterLineRightContainer {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .startHeadingDataBadges,.pointsHeadingDataBadges,.targetHeadingDataBadges{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1px;
    color: #1E3264;
  }
  #root > div.mainContainer > div:nth-child(2) > div > div.badgesLeftSection > div.badgesLeftSectionHeaderContainer > div:nth-child(2) > div{
    width: 302px !important;
  }
  @media (max-width: 1370px) {
    .tableHeadingsBadges {
      width: 54.86%;
    }
  
    .badgesLeftSectionHeaderContainer {
      width: 55.5%;
    }
  }
  .badgesRightSectionHeaderContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #FFA894;;
    height: 74px;
    padding: 0 24px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 155.6%;
  }
  .badgesFilterHeader {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #1e3264;
    display: flex;
  }
  .organizedBadgeImage{
    position: absolute;
    right: 74%; 
    /* margin-top: -80px;  */
    z-index: 6;
  }
  .testing{
    border: 5px solid red;
  }
  .badgesDataWrapper{
    display: flex;
    cursor: pointer;
    height: 60px;
    animation: hideMe 2s forwards;
  }
  @keyframes hideMe{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
 #root > div.mainContainer > div:nth-child(2) > div > div.badgesLeftSection > div.badgesLeftSectionHeaderContainer > div:nth-child(2) > div{
    min-height: 0rem !important;
  }