.currentTempText {
  font-family: Montserrat;
  font-weight: 300;
  font-size: 77px;
  line-height: 15px;
  color: #3b4a57;
  padding-left: 5%;
}

.cityName {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #3b4a57;
}

.minMaxTempText {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.114286px;
  color: #3b4a57;
}

.widgetHourText {
  font-family: Montserrat;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.114286px;
  color: #3b4a57;
}

.widgetTempText {
  font-family: Montserrat;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.114286px;
  color: #3b4a57;
}

.widgetImage {
  width: 28px;
  aspect-ratio: 1;
  display: block;
  margin: 5px auto;
}
