.homepageContainer {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.homepageLeftSection {
  width: 66.7%;
  background-color: #fff;
  overflow-y: scroll;
}

.homepageHeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffa894;
  height: 74px;
  position: fixed;
  width: 55.95%;
  z-index: 3;
}

.homepageHeader {
  padding-left: 24px;
  font-family: Montserrat;
  font-weight: bold;
  font-size: 25px;
  line-height: 32px;
  color: #1e3264;
}

.homepageDate {
  padding-right: 24px;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #1e3264;
}

.homepageFooterContainer {
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 8px;
  position: fixed;
  bottom: 0;
  width: calc(54% - 10px);
  background-color: #fff;
  justify-content: center;
  z-index: 1;
}

.buttonContainer {
  align-self: flex-end;
  padding: 4px 0;
  display: flex;
  height: 8vh;
  align-items: center;
}

.homepageRightSection {
  width: 33.3%;
  background-color: #fff;
  overflow-y: scroll;
  overflow-x: hidden;
}

.subHeading {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3b4a57;
}

#loader .p-dialog-header {
  display: none;
}

#loader .p-dialog-content {
  background-color: transparent;
  padding: 0;
}

#loader {
  box-shadow: none;
}

#newTeamButton > span {
  padding: 12px 24px;
}

#newRewardButton > span {
  padding: 12px 24px;
}

#newMessageHomeScreen > span {
  padding: 12px 24px;
}
#powerBIButton > span {
  padding: 12px 24px;
}
#root
  > div.mainContainer
  > div:nth-child(2)
  > div
  > div.homepageLeftSection
  > div:nth-child(2)
  > div:nth-child(1) {
  margin-top: 100px;
}
@media (max-width: 1370px) {
  .homepageHeaderContainer {
    width: 55.5%;
  }
}
