.PeopleDetailContainer {
  /* width: 50.3%; */
  background-color: #f6f7f9;
  height: 102%;
  /* overflow-y: auto; */
  display: flex;
  justify-content: space-between;
}

.PeopleDetailLeftSection1 {
  display: flex;
  flex-direction: column;
  /* width: 62.7%; */
  width: 562.82px;
  height: 820.3px;
  background-color: #fff;
  /* overflow-y: auto; */
}

.PeopleDetailHeaderLeftContainer1 {
  position: absolute;
  width: 563px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffa894;
  height: 74px;
  border-radius: 16px 0px 0px 0px;
  border-top-right-radius: 16px;
}
.PeopleDetailHeaderRightContainer1 {
  position: absolute !important;
  background-color: #edbdbd;
  height: 74px;
  overflow-y: hidden;
  border-radius: 0px 16px 0px 0px;
  display: flex;
  width: 412px;
  margin-top: -34px;
}

.team-heading {
  padding-left: 32px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 32px;
  color: #1e3264;
}

.team-heading1 {
  padding-left: 32px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 32px;
  color: #3b4a57;
}

.PeopleDetailsRightSection1 {
  width: 0vw;
  background-color: #fff;
  /* overflow: hidden; */
  /* height: 500px; */
}
.PeopleDetailsRightSectionExpand1 {
  width: 426.48px;
  background-color: #fff;
  /* overflow-x: hidden; */
  transition: width 0s ease-out;
  height: 820px;
}

.PeopleDetailsRightSectionClose {
  position: relative;
  width: 0vw;
  background-color: #fff;
  /* overflow: hidden; */
  transition: width 0.2s ease;
  /* height: 500px; */
}

#people-detail .p-dialog-header {
  display: none !important;
}

#people-detail_content {
  padding: 0px !important;
  border-radius: 16px;
  /* overflow-y: hidden; */
}
#people-detail_content + div {
  display: none;
}

#people-detail [role='tablist'] {
  height: 74px;
  margin-bottom: 0 !important;
}
#peopledetail-avatar {
  width: 82px;
  height: 82px;
  background-color: white;
  border-radius: 100px;
  position: relative;
  top: 30px;
  border: 6px solid #ffa894;
  right: 39px;
  box-shadow: -2px -5px 5px rgba(27, 31, 35, 0.05);
}
.flexItems {
  display: flex;
  /* flex-direction: row; */
  align-items: center;
}
.dateWrapper {
  display: flex;
  flex-flow: row wrap;
  width: 274px;
}
.dateContainer {
  height: 200px;
  overflow-y: auto;
}
.leftText {
  width: 240px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20.2px;
  color: #3b4a57;
}

.rightText {
  width: 142px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #3b4a57;
}
.rightText11 {
  /* width: 100px; */
  font-family: 'Montserrat';
  font-style: normal;
  /* font-weight: 600; */
  font-size: 14px;
  line-height: 22px;
  color: #3b4a57;
}
.rightText12 {
  /* width: 100px; */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #3b4a57;
}
.rightText31 {
  width: 75px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #3b4a57;
}
.rightText1 {
  width: 80px;
  font-family: 'Montserrat';
  font-style: normal;
  /* font-weight: 600; */
  font-size: 14px;
  line-height: 22px;
  color: #3b4a57;
}
.routeContainer {
  /* display: flex;
  align-items: center; */
  margin-right: 8px;
  width: 110px;
  height: 32px;
  border-radius: 16px;
  background-color: #f6f7f9;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-right: 24px;
  padding-left: 24px;
  margin-bottom: 8px;
  text-align: center;
  /* padding: 6px, 24px, 6px, 24px; */
}
.informationText1 {
  margin-top: 20px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  width: auto;
}
.informationText01 {
  margin-top: 24px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}
.rightText2 {
  margin-left: 116px;
  margin-top: 20px;
  font-family: 'Montserrat';
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
}
.rightText3 {
  margin-left: 36px;
  margin-top: 20px;
  font-family: 'Montserrat';
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
}
#TeamDropdown + ul {
  /* overflow-y: auto; */
  height: auto;
  max-height: 20vh;
  margin-top: 10px;
}
#TeamDropdown + ul > li {
  background-color: #f6f7f9;
}
#peopleEditCancel:hover {
  cursor: pointer;
}
#peopleEditButton {
  cursor: pointer;
  background-color: #ffffff;
  color: #0051c2;
  border: none;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Montserrat';
  margin-right: 10px;
}
#peopleEdit-nextButton > span {
  padding: 12px 24px;
  /* height: 40%; */
}
.peopleAvatarContainer {
  position: relative;
  top: 30px;
  right: 39px;
  box-shadow: -2px -5px 5px rgba(27, 31, 35, 0.05);
  border-radius: 100px;
}
.groupImagePlaceHolder1 {
  display: none !important;
  position: absolute;
  height: 44px;
  width: 44px;
  border-radius: 100px;
  bottom: -10px;
  right: -10px;
  background-color: #ffffff;
  border: 6px solid #a4dedf;
}
.groupNameImageContainer {
  position: absolute;
  height: 44px;
  width: 44px;
  border-radius: 100px;
  bottom: -10px;
  right: -10px;
  box-shadow: -2px -5px 5px rgba(27, 31, 35, 0.05);
}
.dividerLine {
  border: 1px solid #c2c9d1;
  margin-left: 32px;
  margin-right: 32px;
}
.peopleDetailButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 32px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.editButtonPeople {
  border: none;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Montserrat';
  margin-right: 10px;
}
.tabMarking1 {
  position: absolute;
  bottom: 0px;
  height: 5px;
  background-color: #3b4a57;
  width: 99px;
  margin-left: 32px;
}

/* #peopleDetailMainContainer::-webkit-scrollbar {
  width: 16px;
}

#peopleDetailMainContainer::-webkit-scrollbar-track {
  background-color: rgba(250, 255, 0, 0.3);
  border-radius: 10px;
}
#peopleDetailMainContainer::-webkit-scrollbar-thumb {
  background-color: rgba(250, 255, 0, 0.3);
  border-radius: 10px;
  padding-left: 2px;
  padding-right: 2px;
} */
#peopleDetailMainContainer > div:nth-child(10) > span:nth-child(2) {
  white-space: nowrap;
}
#people-detail_content
  > div
  > div.PeopleDetailsRightSectionExpand1
  > div
  > div:nth-child(2)
  > div
  > div:nth-child(3) {
  margin-top: 59px !important;
}
.pointsMarker {
  margin-top: 10px;
  margin-bottom: -10px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #73808c;
}
