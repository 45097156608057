.rightSideHeaderContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #a4dedf;
  height: 74px;
  padding: 0 24px;
}
.rightSideHeader {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 155.6%;
  color: #1e3264;
}
.rightSideHeaderTeams {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 154%;
  color: #3b4a57;
}
.rightSideInformationContainer {
  padding-left: 6%;
}
.rightSideInformationHeader {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  display: flex;
  align-items: center;
  color: #3b4a57;
}
.rightSideInformationBody {
  margin-top: 4%;
  display: flex;
  flex-flow: wrap;
}
.rightSideInformationBody1stColumn {
  width: 35%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 154%;
  color: #3b4a57;
}
.rightSideInformationBody2ndColumn {
  width: 65%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 154%;
  color: #3b4a57;
}
/* To implement Y direction scroll */
.rightSideTeamMembers {
  height: calc(92vh - 466px);
  overflow-y: auto;
  padding-bottom: 12px;
}
.rightSideTeamMembersContainer {
  padding-left: 6%;
  margin-top: 4%;
  display: flex;
  flex-direction: column;
}

.rightSideTeamMembersHeader {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;

  color: #3b4a57;
}
.rightSideproductsCarousel {
  margin-bottom: 5%;
}
/* To remove the paginator from carousel */
.carouselIndicator {
  display: none !important;
}
/* To change the css of container of carousel */
div.rightSideproductsCarousel > div > div > div > div > div {
  margin-top: 5%;
  padding-left: 5%;
}
.carouselTeamNameContainer {
  max-width: 80px;
  /* border: 1px solid black; */
}
.carouselTeamName {
  text-align: center;
  word-wrap: break-word;
  margin-top: 15%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  color: #1e3264;
  cursor: pointer;
  /* To replace extra alphabets with .... */
  width: 70px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.carouselTeamNameLine {
  border: 2px solid #edbdbd;
  margin-top: -8%;
  background-color: #edbdbd;
}
.carouselTeamNameLineHidden {
  visibility: hidden;
}
.rightSideAvatarAndNameContainer {
  margin-top: 4%;
  display: flex;
  flex-flow: wrap;
}
/* To change the css of Avatar in Team member */
div.rightSideTeamMembers > div > div {
  width: 15%;
}
.teamMemberName {
  margin-left: 5%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1px;
  color: #1e3264;
}
.teamsFooter {
  height: 8.4vh;
  bottom: 0;
  width: 27%;
  position: fixed;
  background-color: white;
  /* border: 1px solid red; */
  margin-left: -1.7%;
}
.teamsFooterLine {
  border: 1px solid #c2c9d1;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 0px;
  background-color: #c2c9d1;
}
.newTeamButton {
  float: right;
  margin-right: 8%;
  cursor: pointer;
  background-color: #0051c2;
  color: white;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  height: 2.5rem;
  width: 8rem;
  border-radius: 40px;
  border: none;
  margin-top: 0.8%;
}
.teamFooterResetButton {
  /* float: right;
  margin-right: 10%;
  margin-top: 1.5%; */
  background-color: white;
  border: none;
  cursor: pointer;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  margin-right: 10px;
  /* line-height: 16px;
  height: 50%;
  width: 10%;
  margin-left: 30%; */
}
/* To make line below slider Avatar visible when clicked on Avatar */
/* #tdContainer:focus > div.carouselTeamNameContainer > hr {
  border: 2px solid #edbdbd;
} */
/* To remove the caret from carousel */
div.rightSideproductsCarousel
  > div
  > div
  > div
  > div
  > div
  > div
  > div.p-carousel-item.p-carousel-item-active.p-carousel-item-start
  > div
  > div {
  caret-color: transparent;
}
.product-itemForTwo {
  margin-left: 15%;
}
div.rightSideproductsCarousel
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > div {
  caret-color: transparent;
}
.forOneTeam {
  margin-top: 5%;

  min-height: 120px !important;
}
.forOneTeamAvatar {
  margin-top: 1%;
  display: flex;
  justify-content: center;
}
.forOneTeamTeamName {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  /* identical to box height, or 17px */
  text-align: center;
  color: #1e3264;
  margin-top: 1.5%;
  margin-left: 40%;
  margin-right: 40%;
  word-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.forOneTeamHR {
  border: 2px solid #edbdbd;
  background-color: #edbdbd;
  width: 20%;
  margin-top: -1.5%;
}
/* Css for Sigle Team avatar */
div.forOneTeamAvatar > div > div > div {
  margin-right: 1%;
}
.newTeamAndEditContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 8vh;
  margin-top: -2%;
}

#newTeamButton > span {
  padding: 12px 24px;
}
