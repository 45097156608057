.weatherNotificationHeading {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3b4a57;
}

.weekNumberText {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #73808c;
  padding: 0 40px;
  /* width: 350px; */
}

.weatherNotificationHistoryText {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  color: #73808c;
}

.weatherHistoryDayText {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  color: #73808c;
}

.weatherHistoryMainContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  background-color: #f6f7f9;
  height: 40px;
}

.weatherNotificationTable {
  width: 12.5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 180px;
}

.weatherNotificationTableContent {
  height: 33.3%;
  display: flex;
  align-items: center;
}
