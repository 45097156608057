.messageDialogBody{
    /* background-color: red; */
    /* width: 1005px ; */
    height: 820px ;
    /* overflow: scroll; */
    /* overflow-x: hidden; */
    display: flex;
}

.leftMsgDialogBody{
    /* background-color: blue; */
    width: 578px;
    overflow-y: scroll;
    height: 86vh ;
}

.leftMsgDialogBodyHeader{
    display: flex;
    align-items: center;
    height: 71px;
    padding-left: 32px;
    background-color: #FFA894;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    color: #1E3264;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 5;
}

.leftMsgDialogBodyData{
    padding-bottom: 80px;
}

.leftMsgDialogBodyTitle,.leftMsgDialogBodyContent{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
    color: #3B4A57;
    margin-left: 32px;
    margin-top: 34px;
}

.leftMsgDialogBodyInput{
    margin-top: 20px;
    margin-left: 32px;
    width: 500px;
}
.leftMsgDialogBodyInputTest{
    width: 500px !important;
    margin-top: 20px !important;
    margin-left: 32px !important;
    background-color: #F6F7F9 !important;
    box-shadow: inset 0px -2px 0px #C2C9D1 !important;
    border-radius: 16px !important;
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
    font-family: Montserrat !important;
    color: #3B4A57 !important;
    font-weight: 600 !important;
    font-size: 1rem !important;
    padding: 20px !important;
}
.leftMsgDialogBodyInputTest:hover{
    border-bottom: 1px solid #C2C9D1 !important;
}
.leftMsgDialogBodyInputTest:focus{
    /* box-shadow: 0 0 7px #0051C2 !important;
    border: 3px solid blue !important; */
    box-shadow: none !important;
    border: none !important;
}

.leftMsgDialogBodyInputTest:hover{
    background-color: rgb(235, 235, 235) !important;
}
.leftMsgDialogBodyTextArea{
    margin-top: 20px;
    margin-left: 32px;
    width: 500px;
}

.msgCheckBoxAndLabelWrapper{
    display: flex;
    margin-top: 20px;
    margin-left: 32px;
    align-items: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #3B4A57;
}
.msgTextAreaCount{
    margin-top: 5px;
    margin-left: 32px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #262C30;
    float: right;
    margin-right: 31px;
}

.leftMsgDialogBodyFooter{
    position: absolute;
    bottom: 0;
    width: 55.8%;
    background-color: white;
    height: 69px;
    /* border-top: 1px solid #C2C9D1; */
}

.sendButtonMsg{
    font-size: 16px !important;
    height: 37px;
    margin-right: 24px;
    margin-left: 32px;
}

.cancelButtonMsg,.templatesDeleteEnableButton1, .templatesDeleteDisableButton,.useAsATemplate{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #0051C2;
    background-color: white;
    border: none;
    cursor: pointer;
}

.templatesDeleteDisableButton{
    /* cursor: none; */
    color: #A7A7A7;
    pointer-events: none;
    margin-left: 32px;

}

.rightMsgDialogBody{
    width: 429px;
    height: 86vh ;
    /* overflow-y: auto; */
    padding-bottom: 69px;
}

.msgTabSelection{
    display: flex;
    align-items: center;
    height: 71px;
    background-color: #EDBDBD;
}

body > div > div:nth-child(3) > div > div > div > div > div > div.rightMsgDialogBody > div > div{
    position: sticky;
    top: 0;
    width: 100%;
}
body > div.sc-m9cfk7-1.bHBLUE > div:nth-child(3) > div > div > div > div > div > div.rightMsgDialogBody > div > div.sc-1vf37w-1.cIqkep{
    z-index: 400;
}

.recipientsScreenHeader,.templatesScreenHeader,.graphicScreenHeader{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #3B4A57;
    margin-left: 32px;
    margin-top: 34px;
}

.graphicScreenHeaderBody{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: #29545C;
    margin-left: 32px;
    margin-top: 20px;
    padding-right: 30px;
}

.recipientsScreenBody,.templatesScreenBody,.graphicScreenSubHeader{
    margin-left: 32px;
    margin-top: 25px;
}

.graphicScreenSubHeader{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #29545C;
}

.defaultGraphicBody{
    display: flex;
    margin-left: 32px;
    margin-top: 25px;
    cursor: pointer;
}
.graphicCollectionInnerBody{
    display: flex;
    margin-left: 15px;
    /* margin-top: 25px; */
    cursor: pointer;
    margin-bottom: 10px;
}
.graphicTick{
    height: 10px;
    position: absolute;
    margin-left: 56px;
}

.graphicCollectionHeader{
    margin-left: 32px;
    margin-top: 25px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #29545C;
}

.graphicCollectionBody{
    display: flex;
    margin-top: 25px;
    margin-left: 20px;
    max-width: 400px;
    flex-wrap: wrap;
}

/* .spaceDiv{
    width: 12px;
} */
.templatesScreenBodyHeader{
    display: flex;
    align-items: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    align-items: center;
    color: #29545C;
    gap:10px;
    margin-bottom: 12px;
}

.templatesScreenBodyBody{
    margin-right: 32px;
    margin-left: 37px;
    margin-top: 8px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: #29545C;
    /* height: 60px; */
    overflow: hidden;
    text-overflow: ellipsis;
    /* white-space: nowrap; */
    margin-bottom: 10px;
   display: -webkit-box;
   -webkit-line-clamp: 3; /* number of lines to show */
           line-clamp: 3; 
   -webkit-box-orient: vertical;
}

.templatesDownArrow{
    cursor: pointer;
}

.recipientsScreenWrapper{
    display: flex;
    align-items: center;
    gap: 16px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #3B4A57;
    margin-bottom: 6px;
}

.recipientsScreenSearchBody{
    margin-top: 34px;
    margin-left: 32px;
}

.recipientsSearch{
    width: 363px;
}
.check{
    overflow-y: auto;
    padding-bottom: 50%;
}
.msgCheckAvatarNameContainer{
    display: flex;
    align-items: center !important;
    margin-top: 15px;
    gap:10px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #1E3264;
    margin-left: 50px;
}

.recipientsScreenFooter,.templatesScreenFooter,.graphicScreenFooter{
    height: 69px;
    position: fixed;
    bottom: 0;
    width: 430px;
    background-color: white;
}

.templatesScreenFooterButtonWrapper{
    display: flex;
    height: 69px;
    align-items: center;
    justify-content: space-between;
}
.graphicScreenFooterButtonWrapper{
    display: flex;
    height: 69px;
    align-items: center;
    justify-content: flex-end;
    margin-right: 25px;
}
.templatesUseButton{
    height: 37px;
    margin-right: 25px;
}
.graphicUseButton{
    height: 37px;
}

.templatesDeleteEnableButton1{
    margin-left: 32px;
    cursor: pointer;
    color: red;
}
.msgTab1,.msgTab2,.msgTab3{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #3B4A57;
    border: none !important;
    box-shadow: none !important;
    cursor: pointer;
    margin-left: 25px !important;
}

body > div > div:nth-child(3) > div > div{
    padding: 0px 0px 0px 0px;
}

body > div > div:nth-child(3) > div > div > div{
    padding: 0px 0px !important;
    margin: 0px 0px !important;
}

body > div > div:nth-child(3) > div > div > div > div{
    margin-bottom: 0px !important;
}

body > div > div:nth-child(3) > div > div > div{
    overflow: hidden !important;
}

input[type='radio']:after {
    width: 16px;
    height: 16px;
    border-radius: 15px;
    margin-top: -5px;
    position: relative;
    background-color: white;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 3px solid #73808C;
    cursor: pointer;
}

input[type='radio']:checked:after {
    width: 8px;
    height: 8px;
    border-radius: 15px;
    margin-left: -1.1px;
    position: relative;
    /* background-color: #ffa500; */
    content: '';
    display: inline-block;
    visibility: visible;
    border: 8px solid #0051C2;
    cursor: pointer;
    /* margin-left: 30px; */

}

.leftMsgDialogBodyTextAreaPrimeReacts{
    margin-left: 32px;
}

.recipientsLoader{
    margin-top: 45px;
    margin-left: 130px;
}

.msgSuptoDriverCheckbox{
    margin-top: 7px;
}

.recipientsFooterLine,.templatesFooterLine,.graphicFooterLine{
    border:1px solid #C2C9D1;
    margin-left:32px;
    margin-right:25px;
}
.supToDriverDialog{
    width:1005px;
    height:86vh;
    overflow:'hidden'
}

.leftMsgDialogBodyFooterLine{
    border:1px solid #C2C9D1;
    margin-left:32px;
    margin-right:32px;
}

.leftMsgDialogBodyFooterLineWrapper{
    display: flex;
    height: 65px;
    align-items: center;
    justify-content: space-between;
    padding-left: 25px;
}

@media (min-width:48em)
{
    body > div > div:nth-child(3) > div{
        max-width: 100rem;
    }
}
.templateMessageHeading{
    width: 300px;
    margin-left: 5px;
    margin-top: 5px;
    overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2; 
   -webkit-box-orient: vertical;
}
.useAsATemplate,.useAsATemplateDisable{
    /* margin-right: 128px; */
    white-space: nowrap;
    margin-bottom: 7px;
}
.useAsATemplateDisable{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #A7A7A7;
    background-color: white;
    border: none;
    cursor:pointer;
    pointer-events: none;
}