.avatarOuterDiv {
  aspect-ratio: 1;
  border-radius: 100px;
  display: grid;
}

.avatarInnerContainer {
  background-color: #fff;
  padding: 2.5px;
  margin: 2.5px;
  border-radius: 100px;
  display: grid;
  place-items: center;
}

.nameInitialsInAvatar {
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  color: #fff;
  font-family: Montserrat;
  font-weight: 500;
}
