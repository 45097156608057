.recruitmentHintsHeading {
  padding-left: 24px;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 18px;
  line-height: 74px;
  color: #1e3264;
  background-color: #ffc226;
  height: 74px;
}

.recruitmentHintsFooter {
  display: flex;
  flex-direction: column;
  margin: 0px 1%;
  position: fixed;
  bottom: 0;
  width: 26%;
  background-color: #fff;
}

.greetingsText {
  padding: 0 24px 10vh 24px;
  font-family: Montserrat;
  font-size: 13px;
  line-height: 20px;
  color: #3b4a57;
}

.greetingsText li {
  font-weight: bold;
}

.greetingHeader {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3b4a57;
}

.greetingDate {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  color: #73808c;
}
.greetingHeadLine,.msgHeadLine{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  display: flex;
  align-items: center;
  color: #3B4A57;
  padding-left: 24px;
  margin-top: 30px;
}

#root
  > div.mainContainer
  > div:nth-child(2)
  > div
  > div.homepageRightSection
  > div.greetingsText
  > div
  > b:nth-child(7)
  > a {
  color: #3b4a57;
}
.msgHeadLine{
  margin-top: 100px;
}
