.wholeContactsWrapper {
  display: flex;
  justify-content: space-between;
  height: 100%;
  overflow: hidden !important;
}
.leftSideContacts {
  width: 4953.813%;
  background-color: #fff;
  overflow-y: scroll;
  padding-bottom: 8vh;
}
.rightSideContacts {
  width: 2439.938%;
  background-color: #fff;
  overflow-y: scroll;
}
.leftSideContactsHeader {
  padding-left: 24px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 74px;
  color: #1e3264;
  background-color: #ffa894;
  height: 74px;
  display: flex;
  justify-content: space-between;

  /* position: fixed;
  width: 56.08%;
  z-index: 3; */
}
.rightSideContactsFilterHeader {
  padding-left: 15px;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 18px;
  align-items: center;
  display: flex;
  color: #1e3264;
  background: #edbdbd;
  height: 74px;
  flex-shrink: 0;
  position: fixed;
  width: 27.25%;
}
.rightSideContainerText {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3b4a57;
  padding-left: 15px;
  margin-bottom: 18px;
  padding-top: 80px;
}
.rightSideContainerDate {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #1e3264;
  padding-left: 15px;
  letter-spacing: 0.1px;
  margin-top: 10px;
}

.rightSideContainerDateTime {
  margin-bottom: 16px;
}
.rightSideContainerTime {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #1e3264;
  padding-left: 15px;
}
.rightSideContainerChanges {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #1e3264;
  padding-left: 15px;
  letter-spacing: 0.1px;
}
.rightSideContactsFilterContainer {
  margin-top: 25.47px;
  flex-grow: 1;
  overflow: auto;
}
.columnContact {
  width: 4vw;
  white-space: nowrap;
  line-height: 2vh;
  color: #1e3264;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  padding-left: 0px;
}
.columnNumber {
  width: 5vh;
  color: #1e3264;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.1px;
  color: #000000;
  padding-left: 0px;
}
.columnRole {
  width: 4vw;
  color: #1e3264;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #262c30;
  padding-left: 0px;
}

/* To remove the border from datatable */
.p-datatable .p-datatable-tbody > tr > td {
  border: none !important;
  padding: 1vh 0vh !important;
}
.p-datatable .p-datatable-tbody > tr {
  height: 5vh;
}
/* To change the CSS of header of Datatable */
div.contactsDataTableContainer
  > div
  > div
  > table
  > thead
  > tr
  > th.p-sortable-column {
  background-color: white;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1px;
  border-bottom: 0.1vh solid #3b4a57;
}
/* To change the arrow Image in filter of datatable */
.pi-sort-alt:before,
.pi-sort-amount-down:before,
.pi-sort-amount-up-alt:before {
  content: url('../../images/arrow.png') !important;
}
.leftSideContactsFooter {
  height: 8vh;
  bottom: 0;
  width: 55.5vw;
  position: fixed;
  background-color: white;
}
.leftSideContactsFooterLine {
  border: 1px solid #c2c9d1;
  margin-left: 24px;
  margin-right: 0.8vw;
  margin-top: 0vh;
  background-color: #c2c9d1;
}
.deleteButtonTextEnable {
  margin-right: 91.5%;
  position: absolute;
  margin-top: 1%;
  margin-bottom: 1%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
  color: #0051c2;
}
.deleteButtonTextDisable {
  margin-right: 91.5%;
  position: absolute;
  margin-top: 1%;
  margin-bottom: 1%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #a7a7a7;
}
.leftSideContactsFooterSelectButton {
  float: right;
  margin-top: 1%;
  margin-bottom: 1%;
  background-color: white;
  border: none;
  cursor: pointer;
  color: #0051c2;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  height: 50%;
  width: 13%;
}
.leftSideContactsFooterSelectButton1 {
  float: right;
  margin-right: 20px;
  margin-top: 1%;
  margin-bottom: 1%;
  background-color: white;
  border: none;
  cursor: pointer;
  color: #0051c2;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  height: 50%;
  width: 13%;
}
.footerButtonContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.5vh;
  height: 8vh;
  margin-top: -10px;
}
.editButtonTextEnable {
  margin-right: 2%;
  margin-top: 1%;
  margin-bottom: 1%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
  color: #0051c2;
}
.editButtonTextDisable {
  margin-right: 2%;
  margin-top: 1%;
  margin-bottom: 1%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #a7a7a7;
  pointer-events: none;
}
#addNewButton > span {
  padding: 12px 24px;
}
#contactDataTable .p-datatable-tbody > tr {
  border-bottom: 1px solid #eaedf1;
  padding-left: 24px;
}
