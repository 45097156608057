.pulseFilterWholeWrapper {
  padding-left: 24px;
  padding-top: 34px;
}
.pulseFilterLocationHeading,
.pulseFilterIntervalHeading {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  display: flex;
  align-items: center;
  color: #3b4a57;
}
.pulseFilterLocationBody,
.pulseFilterIntervalBody {
  margin-top: 21px;
}
.pulseLocationSearchBar {
  width: 80%;
  height: 72px;
  margin-top: 20px;
}
.pulseFilterLocationHeading {
  margin-top: 25px;
}
#root
  > div.mainContainer
  > div:nth-child(2)
  > div
  > div.dailyPulseContainer
  > div.dailyPulseRightSection
  > div:nth-child(1)
  > div.pulseFilterWholeWrapper
  > div.pulseFilterLocationBody
  > div {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #3b4a57;
}
#root
  > div.mainContainer
  > div:nth-child(2)
  > div
  > div.dailyPulseContainer
  > div.dailyPulseRightSection
  > div:nth-child(1)
  > div.pulseFilterWholeWrapper
  > div.pulseFilterIntervalBody
  > div {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #3b4a57;
}
.locationlistContainer {
  padding-left: 30px;
  padding-top: 15px;
}
#root
  > div.mainContainer
  > div:nth-child(2)
  > div
  > div.dailyPulseContainer
  > div.dailyPulseRightSection
  > div:nth-child(1)
  > div.pulseFilterWholeWrapper
  > div.locationlistContainer
  > div {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #3b4a57;
}
.dailyPulseScreenRightSectionFooter {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  width: 25%;
  background-color: #fff;
}
.voteBlock {
  margin-top: 10.02px;
  margin-right: 4px;
  font-family: 'Montserrat';
  font-weight: 400;
  font-style: normal;
  line-height: 24px;
  font-size: 16px;
  color: '#3B4A57';
}

.percentBlock {
    margin-top: 10.02px;
    margin-right: 4px;
    font-family: 'Montserrat';
    font-weight: 600;
    font-style: normal;
    line-height: 150%;
    font-size: 16px;
    color: '#3B4A57';
  }