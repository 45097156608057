#sideMenu {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}

#leftSideMenu {
  margin-top: -8px;
}

#sideMenu #leftSideMenu [aria-label='true'] {
  background-color: #f7cc7a;
  border-radius: 8px;
}
#sideMenu #leftSideMenu [aria-label='false'] {
  background-color: transparent;
}

#sideMenu #leftSideMenu [aria-label='false']:hover {
  background-color: #eaedf1;
  border-radius: 8px;
}

#sideMenu #leftSideMenu > li > span > div > div {
  color: #3b4a57;
  font-size: 16px;
}
/* #sideMenu #leftSideMenu .gPndYM .bGsfln {
  color: #3b4a57;
  font-size: 16px;
} */

.sideMenuFooterText {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  align-items: center;
  color: #c2c9d1;
  text-align: center;
  /* position: fixed;
  bottom: 0;
  left: 7.5%;
  transform: translateX(-50%);
  width: 15%; */
}

.logOutStyle {
  font-size: 16px;
  line-height: 20px;
  color: #3b4a57;
  align-items: center;
  align-items: center;
  display: flex;
  /* justify-content: center; */
  cursor: pointer;
  height: 8vh;
  align-items: center;
  margin-left: 40px;
}

#logOutDialog_content {
  padding: 0;
  border-radius: 16px;
}

#logOutDialog {
  box-shadow: none;
}

.logOutHeader {
  background-color: #ffc226;
  height: 72px;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 18px;
  line-height: 72px;
  padding-left: 32px;
  color: #1e3264;
}

.logOutBody {
  font-family: Montserrat;
  font-size: 18px;
  line-height: 28px;
  color: #3b4a57;
  padding: 32px 32px 75px 32px;
}

.logOutFooterText {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #0051c2;
  padding-right: 5%;
  cursor: pointer;
}

.logOutFooter {
  padding: 16px 32px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

#logOutButton > span {
  padding: 12px 24px;
}
/* #leftSideMenu > li > span > svg {
  margin-bottom: 4px;
} */
