.headerContainer {
  position: relative;
  background: #fff;
  display: flex;
  align-items: center;
  height: 60px;
  justify-content: space-between;
  box-shadow: 0px 15px 22px rgba(0, 0, 0, 0.06), 0px 3px 8px rgba(0, 0, 0, 0.08),
    0px 1px 1px rgba(0, 0, 0, 0.08), 0px 3px 1px rgba(0, 0, 0, 0.04);
}

.headerLogo {
  width: 34px;
  height: 35px;
  margin: 0 10px 0 10px;
}

.headerFlexAlignment {
  display: flex;
  align-items: center;
}

#heading {
  font-size: 18px;
  font-weight: 800;
}

.email {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 10px;
  display: flex;
  align-items: center;
  color: #c2c9d1;
  padding-right: 20px;
}

#language > div {
  display: none;
}

#language [aria-selected='true'] {
  background-color: #edbdbd;
}

#language > button > span > label {
  color: #3b4a57;
}

#language > svg {
  padding-right: 15px;
  width: 50px;
}

/* #headerDropdown .biFcXQ {
  overflow-y: hidden;
  max-height: none; 
} */

/* #headerDropdown .dqxlU {
  min-width: 250px;
} */

#headerDropdown {
  min-width: 300px;
}
#headerDropdown1 {
  min-width: 381px;
}
#headerDropdown > span {
  top: calc(50% - 0.6rem);
}
.dropdown{
  display: none;
}
.dropdowna{
  display: block;
}

/* .maindroplist{
  list-style-type: none;
  /* margin-top: 4%;
  margin-left: 5%;
  padding: 0; */
  /* padding-left: 10%;
  padding-top: 3%; 
  overflow: hidden;
} */
 ul{
  list-style-type: none;
  margin:0;
  padding: 0;
  overflow: hidden;

} 
.mainhead{
  text-decoration: none;
  font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 125%;
color: #3B4A57;
padding-top: 18px;
padding-left: 16px;
height: 56px;
}
.nodecor{
  text-decoration: none;
  cursor: pointer;
}
.internalli{
  padding-left: 22px;
  /* //margin-top: 0; */
   /* padding-top: -15px;  */
   padding-top: 7px;
   margin-top: 12px;
   margin-bottom: 20px;
   font-family: 'Montserrat';
   font-style: normal;
   font-weight: 600;
   font-size: 16px;
   line-height: 125%;
   color: #3B4A57;
   cursor: pointer;
   height: 30px;
   
}
a:visited { text-decoration: none; }
a:hover{
  color:#0051C2;
}
.mainhead:hover{
  background-color: #EAEDF1;
  color:#0051C2;
}
.internalli:hover{
  background-color: #EAEDF1;
  color:#0051C2 !important;
}
.imgPos{
  float: right;
  margin-right: 15px;
}
#heading{
  font-family: system-ui !important;
  font-weight: 800 !important;
  font-style: normal !important;
}