.teamDeleteButton {
  padding: 12px 24px 12px 24px;
  border-radius: 40px;
  border: none;
  background-color: #bd2841;
  color: #ffffff;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
}
.teamDeleteButton:hover {
  cursor: pointer;
}
#deleteDialog_content {
  padding: 0;
  border-radius: 16px;
}

#deleteDialog {
  box-shadow: none;
}
.deleteTeamHeader {
  background-color: #ffc226;
  height: 72px;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 18px;
  line-height: 72px;
  padding-left: 32px;
  color: #1e3264;
}
.deleteTeamBody {
  font-family: Montserrat;
  font-size: 18px;
  line-height: 28px;
  color: #3b4a57;
  padding: 32px 32px 75px 32px;
}
.deleteFooterText {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #0051c2;
  padding-right: 5%;
  cursor: pointer;
}
.deleteFooter {
  padding: 16px 32px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
