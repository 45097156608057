.App {
  text-align: center;
}

.mainContainer {
  height: calc(100vh - 60px);
  display: flex;
  background-color: #f6f7f9;
}

::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-track {
  background-color: #f6f7f9;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #eaedf1;
  border-radius: 10px;
  padding-left: 2px;
  padding-right: 2px;
}
