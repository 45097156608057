.dateHeading {
  position: relative;
  padding-left: 115.18px;
  padding-top: 34.33px;
  width: 500px;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.dateSubHeading {
  padding-top: 15px;
  padding-left: 115.18px;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.dateSubHeading2 {
  margin-top: 8px;
  padding-left: 115.18px;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.datesDesc {
  padding-top: 2.32px;
  padding-left: 115.49px;

  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.desc1 {
  position: relative;
  width: 500px;
  height: 40px;
  left: 0px;
  top: 0px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 154%;
  color: #3b4a57;
  padding-left: 115.49px;
  padding-top: 2.32px;
}
.startDateInput {
  padding-left: 115px;
  margin-top: 8px;
  width: 500px;
  padding-right: 34.34px;
  margin-bottom: 2px;
}
.endDateInput {
  padding-left: 115px;
  margin-top: 8px;
  width: 500px;
  padding-right: 34.34px;
  margin-bottom: 2px;
  margin-top: 5px;
}
.desc2 {
  position: relative;
  width: 500px;
  height: 40px;
  left: 0px;
  top: 0px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 154%;
  color: #3b4a57;
  padding-left: 115.49px;
  padding-top: 2.32px;
  padding-bottom: 65px;
}
.datesWrapper {
  width: 475px;
  padding-left: 15px;
}
.datesWrapperfi {
  width: 475px;
  padding-left: 59px;
}
.startingDateCalender,
.endingDateCalender {
  margin-left: 2px;
  margin-top: 12px;
  width: 98%;
  height: 72px;
}
#badgeId-tab4
  > div.datesWrapperfi
  > div.startDateInput
  > div
  > div
  > div
  > div
  > div
  > div
  > div.SingleDatePicker_picker.SingleDatePicker_picker_1.SingleDatePicker_picker__directionLeft.SingleDatePicker_picker__directionLeft_2
  > div
  > div
  > div
  > div.DayPicker_focusRegion.DayPicker_focusRegion_1
  > div.DayPicker_transitionContainer.DayPicker_transitionContainer_1.DayPicker_transitionContainer__horizontal.DayPicker_transitionContainer__horizontal_2 {
  box-shadow: 0px 8px 22px rgba(27, 31, 35, 0.07) !important;
  border-radius: 24px !important;
}
#badgeId-tab4
  > div.datesWrapperfi
  > div.endDateInput
  > div
  > div
  > div
  > div
  > div
  > div
  > div.SingleDatePicker_picker.SingleDatePicker_picker_1.SingleDatePicker_picker__directionLeft.SingleDatePicker_picker__directionLeft_2
  > div
  > div
  > div
  > div.DayPicker_focusRegion.DayPicker_focusRegion_1
  > div.DayPicker_transitionContainer.DayPicker_transitionContainer_1.DayPicker_transitionContainer__horizontal.DayPicker_transitionContainer__horizontal_2 {
  box-shadow: 0px 8px 22px rgba(27, 31, 35, 0.07) !important;
  border-radius: 24px !important;
}
#badgeId-tab4
  > div.datesWrapper
  > div.startDateInput
  > div
  > div
  > div
  > div
  > div
  > div
  > div.SingleDatePicker_picker.SingleDatePicker_picker_1.SingleDatePicker_picker__directionLeft.SingleDatePicker_picker__directionLeft_2
  > div
  > div
  > div
  > div.DayPicker_focusRegion.DayPicker_focusRegion_1
  > div.DayPicker_transitionContainer.DayPicker_transitionContainer_1.DayPicker_transitionContainer__horizontal.DayPicker_transitionContainer__horizontal_2 {
  box-shadow: 0px 8px 22px rgba(27, 31, 35, 0.07) !important;
  border-radius: 24px !important;
}
#badgeId-tab4
  > div.datesWrapper
  > div.endDateInput
  > div
  > div
  > div
  > div
  > div
  > div
  > div.SingleDatePicker_picker.SingleDatePicker_picker_1.SingleDatePicker_picker__directionLeft.SingleDatePicker_picker__directionLeft_2
  > div
  > div
  > div
  > div.DayPicker_focusRegion.DayPicker_focusRegion_1
  > div.DayPicker_transitionContainer.DayPicker_transitionContainer_1.DayPicker_transitionContainer__horizontal.DayPicker_transitionContainer__horizontal_2 {
  box-shadow: 0px 8px 22px rgba(27, 31, 35, 0.07) !important;
  border-radius: 24px !important;
}
