.logFilterSubHeading {
  margin-top: -20px;
  padding-left: 15px;
  line-height: 40px;
  height: auto;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #3b4a57;
}

.filterUpperButtonsWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
}
.filterUpperButtonsWrapperPEC {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  margin-top: 18px;
  margin-left: 20px;
}
.filterLowerButtonsWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
}
.allButton:hover,
.rewardsButton:hover,
.teamButton:hover,
.recruitmentButton:hover,
.weatherButton:hover {
  background-color: #29545c;
  cursor: pointer;
  color: #ffffff;
}
.filterByTypesButtons {
  background-color: #29545c;
  cursor: pointer;
  color: #ffffff;
}
.allButton {
  font-family: Montserrat;
  margin-left: 15px;
  border-radius: 16px;
  background-color: #f6f7f9;
  border: none;
  width: 4.188rem;
  height: 32px;
  line-height: 25px;
  color: #3b4a57;
  font-style: normal;
  font-weight: 600;
}
.teamButton {
  font-family: Montserrat;
  border-radius: 16px;
  background-color: #f6f7f9;
  border: none;
  width: 5.5rem;
  height: 32px;
  color: #3b4a57;
  font-style: normal;
  font-weight: 600;
  margin-left: 1.2vh;
}
.rewardsButton {
  font-family: Montserrat;
  border-radius: 16px;
  background-color: #f6f7f9;
  border: none;
  width: 6.938rem;
  height: 32px;
  /* below is responsible for space between each button i.e. all,team,rewards buttons */
  /* Reverse */
  /* margin-right: 21vh; */
  color: #3b4a57;
  font-style: normal;
  font-weight: 600;
}
.recruitmentButton {
  font-family: Montserrat;
  margin-left: 15px;
  border-radius: 16px;
  background-color: #f6f7f9;
  border: none;
  width: 8.75rem;
  height: 32px;
  line-height: 25px;
  color: #3b4a57;
  font-style: normal;
  font-weight: 600;
}
.weatherButton {
  font-family: Montserrat;
  border-radius: 16px;
  background-color: #f6f7f9;
  border: none;
  width: 6.938rem;
  height: 32px;
  /* below is responsible for space between each button i.e. recruitment and weather buttons */
  /* Reverse */
  margin-right: 14vh;
  color: #3b4a57;
  font-style: normal;
  font-weight: 600;
}
.calenderContainer {
  display: flex;
  flex-direction: column;
  margin-top: 1px;
}
.calenderContainerHeading {
  padding-left: 15px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 40px;
  color: #1e3264;
  height: auto;
  margin-top: 15px;
}
.startingCalender,
.endingCalender {
  margin-left: 15px;
  margin-top: 12px;
  width: 92%;
  height: 72px;
}
.calendersWrapper {
  display: flex;
  flex-direction: column;
}
/* To change the css of Starting calender */
div.calendersWrapper
  > span.p-calendar.p-component.p-inputwrapper.startingCalender
  > input {
  background: #f6f7f9;
  border-radius: 16px;
  box-shadow: inset 0px -2px 0px #c2c9d1;
  width: 342px;
  height: 72px;
}
/* To change the css of Ending calender */
div.calendersWrapper
  > span.p-calendar.p-component.p-inputwrapper.endingCalender
  > input {
  background: #f6f7f9;
  border-radius: 16px;
  box-shadow: inset 0px -2px 0px #c2c9d1;
  width: 342px;
  height: 72px;
}
.peopleSearchContainer {
  display: flex;
  flex-direction: column;
}
.peopleSearchContainerHeading {
  padding-left: 15px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 40px;
  color: #1e3264;
  height: auto;
  margin-top: 15px;
}
.searchBarPeople {
  margin-top: 15px;
  margin-left: 15px;
  width: 92%;
}
.searchBarPeopleResultContainer {
  display: flex;
  flex-direction: column;
}
.searchBarPeopleContainer {
  display: flex;
  flex-direction: column;
}
.peopleDatatableContainer {
  margin-top: 2%;
}
/* To remove the column header from people search datatable */
/* div.peopleDatatableContainer > div > div > table > thead > tr > th {
  display: none;
} */

div.searchBarPeopleContainer
  > div.p-datatable.p-component
  > div
  > table
  > thead
  > tr
  > th {
  background-color: black;
  border: none;
  height: 2rem !important;
}

/* To remove the header of people datatable */
/* div.p-datatable.p-component.peopleDatatable > div > table > thead > tr {
  display: none;
} */
.nameBodyTemplate {
  float: left;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  letter-spacing: 0.1px;
  color: #1e3264;
}
.serachByPeopleContainer {
  padding-left: 7%;
  padding-top: 6%;
  overflow: hidden;
}

.peopleBadgeAvatar {
  border: 1.5px solid orange;
}
.peopleSearchResultWrapper {
  display: flex;
  margin-bottom: 3%;
}
.peopleSearchResultName {
  width: 36vh;
  padding-top: 5%;
  padding-left: 5%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: 0.1px;

  color: #1e3264;
}
.rightSideLogFooter {
  height: 8vh;
  bottom: 0;
  width: 27%;
  position: fixed;
  background-color: white;
  margin-left: -1.9%;
}
.rightSideLogFooterLine {
  border: 1px solid #c2c9d1;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 0px;
  background-color: #c2c9d1;
}
.rightSideLogFooterResetButton {
  float: right;
  margin-right: 10%;
  margin-top: 1%;
  background-color: white;
  border: none;
  cursor: pointer;
  color: #0051c2;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  height: 50%;
  width: 10%;
  margin-right: 31px;
}
.rightSideLogFooterResetButton1 {
  float: right;
  margin-right: 10%;
  margin-top: 1%;
  background-color: white;
  border: none;
  cursor: pointer;
  color: #0051c2;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  height: 50%;
  width: 10%;
  margin-right: 60px;
}
.rightSideLogFooterResetButtonDisable {
  float: right;
  margin-right: 10%;
  margin-top: 1%;
  background-color: white;
  border: none;
  cursor: pointer;
  color: grey;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  height: 50%;
  width: 10%;
  pointer-events: none;
  margin-right: 31px;
}
.rightSideLogFooterResetButtonDisable1 {
  float: right;
  margin-right: 10%;
  margin-top: 1%;
  background-color: white;
  border: none;
  cursor: pointer;
  color: grey;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  height: 50%;
  width: 10%;
  pointer-events: none;
  margin-right: 60px;
}
/* To change the CSS of search by People searchBar */
div.rightSideLog
  > div.rightSideLogFilterContainer
  > div.peopleSearchContainer
  > div.searchBarPeopleContainer
  > div.searchBarPeople
  > div {
  height: 72px;
  background: #f6f7f9;
  border-radius: 16px;
  box-shadow: inset 0px -2px 0px #c2c9d1;
}
.applyButton {
  float: right;
  margin-right: 6%;
  cursor: pointer;
  background-color: #0051c2;
  color: white;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  height: 4vh;
  width: 12vh;
  border-radius: 40px;
  border: none;
  margin-top: 0.5vh;
}
.applyButton:hover {
  transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  -moz-transform: scale(1.1, 1.1);
  font-size: 15px;
}
.applyButtonDisable {
  float: right;
  margin-right: 6%;
  margin-top: 0.5vh;
  cursor: pointer;
  background-color: grey;
  color: white;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  height: 4vh;
  width: 12vh;
  border-radius: 40px;
  border: none;
  pointer-events: none;
}
.peopleSearchResultCheckbox {
  margin-top: 28%;
}
/* #root
  > div.mainContainer
  > div:nth-child(2)
  > div
  > div.rightSideLog
  > div.rightSideLogFilterContainer
  > div.calenderContainer
  > div.calendersWrapper
  > div.endingCalender
  > div
  > div
  > div
  > div
  > div
  > div.SingleDatePicker_picker.SingleDatePicker_picker_1.SingleDatePicker_picker__directionLeft.SingleDatePicker_picker__directionLeft_2
  > div
  > div
  > div
  > div.DayPicker_focusRegion.DayPicker_focusRegion_1
  > div.DayPicker_transitionContainer.DayPicker_transitionContainer_1.DayPicker_transitionContainer__horizontal.DayPicker_transitionContainer__horizontal_2
  > div
  > div:nth-child(2) {
  box-shadow: 0px 8px 22px rgba(27, 31, 35, 0.07) !important;
  border-radius: 24px !important;
}
#root
  > div.mainContainer
  > div:nth-child(2)
  > div
  > div.rightSideLog
  > div.rightSideLogFilterContainer
  > div.calenderContainer
  > div.calendersWrapper
  > div.startingCalender
  > div
  > div
  > div
  > div
  > div
  > div.SingleDatePicker_picker.SingleDatePicker_picker_1.SingleDatePicker_picker__directionLeft.SingleDatePicker_picker__directionLeft_2
  > div
  > div
  > div
  > div.DayPicker_focusRegion.DayPicker_focusRegion_1
  > div.DayPicker_transitionContainer.DayPicker_transitionContainer_1.DayPicker_transitionContainer__horizontal.DayPicker_transitionContainer__horizontal_2
  > div
  > div:nth-child(2) {
  box-shadow: 0px 8px 22px rgba(27, 31, 35, 0.07) !important;
  border-radius: 24px !important;
} */
#root
  > div.mainContainer
  > div:nth-child(2)
  > div
  > div.rightSideLog
  > div.rightSideLogFilterContainer
  > div.calenderContainer
  > div.calendersWrapper
  > div.endingCalender
  > div
  > div
  > div
  > div
  > div
  > div.SingleDatePicker_picker.SingleDatePicker_picker_1.SingleDatePicker_picker__directionLeft.SingleDatePicker_picker__directionLeft_2
  > div
  > div
  > div
  > div.DayPicker_focusRegion.DayPicker_focusRegion_1
  > div.DayPicker_transitionContainer.DayPicker_transitionContainer_1.DayPicker_transitionContainer__horizontal.DayPicker_transitionContainer__horizontal_2 {
  box-shadow: 0px 8px 22px rgba(27, 31, 35, 0.07) !important;
  border-radius: 24px !important;
}
#root
  > div.mainContainer
  > div:nth-child(2)
  > div
  > div.rightSideLog
  > div.rightSideLogFilterContainer
  > div.calenderContainer
  > div.calendersWrapper
  > div.startingCalender
  > div
  > div
  > div
  > div
  > div
  > div.SingleDatePicker_picker.SingleDatePicker_picker_1.SingleDatePicker_picker__directionLeft.SingleDatePicker_picker__directionLeft_2
  > div
  > div
  > div
  > div.DayPicker_focusRegion.DayPicker_focusRegion_1
  > div.DayPicker_transitionContainer.DayPicker_transitionContainer_1.DayPicker_transitionContainer__horizontal.DayPicker_transitionContainer__horizontal_2 {
  box-shadow: 0px 8px 22px rgba(27, 31, 35, 0.07) !important;
  border-radius: 24px !important;
}
div.weatherScreenRightSectionFooter > div:nth-child(1) {
  margin-right: 19px;
}
.applyTextLogFilterCss {
  font-size: 16px;
}
#root
  > div.mainContainer
  > div:nth-child(2)
  > div
  > div.rightSideLog
  > div.rightSideLogFilterContainer
  > div.weatherScreenRightSectionFooter
  > div:nth-child(2)
  > button.rightSideLogFooterResetButtonDisable {
  margin-top: 0% !important;
}
