#editSingleContactDialog_content {
  padding: 0;
  border-radius: 16px;
}
.singleEditContactHeaderContainer {
  display: flex;
  align-items: center;
  height: 71px;
  background-color: #edbdbd;
  padding-left: 31px;
}

.singleEditContactHeader {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 128%;
  color: #1e3264;
}

.singleEditContactNameHeader,
.singleEditContactNumberHeader,
.singleEditContactRoleHeader,
.singleEditContactColorHeader {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  display: flex;
  align-items: center;
  color: #3b4a57;
  padding-left: 31px;
  padding-top: 30px;
}
.singleEditContactNameText,
.singleEditContactNumberText,
.singleEditContactRoleText {
  /* border: 1px solid red; */
  margin-top: 20px;
  padding-left: 31px;
  padding-right: 31px;
  height: 72px;
}
.singleEditContactColorContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  padding-left: 31px;
  padding-right: 31px;
  height: 60px;
  margin-top: 10px;
  margin-bottom: 80px;
}
.singleEditContactFooter {
  position: absolute;
  bottom: 0;
  background-color: white;
  width: 426px;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  height: 80px;
  /* justify-content: flex-end; */
}
.singleEditContactUpdateButton {
  height: 50px;
  margin-right: 25px;
}
.singleEditContactCancelButton {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #0051c2;
  background-color: white;
  border: none;
  cursor: pointer;
}
