.dataFilter {
  margin-top: 108px;
  margin-left: 24px;
  margin-bottom: 24px;
}
.dataFilterHeading,
.dataFilterPointsHeading {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  color: #3b4a57;
}
.dataStartingCalender {
  margin-left: 15px;
  width: 92%;
  height: 72px;
  padding-left: 15px;
}
.dataEndingCalender {
  margin-left: 15px;
  margin-top: 12px;
  width: 92%;
  height: 72px;
  padding-left: 15px;
}
#root
  > div.mainContainer
  > div:nth-child(2)
  > div
  > div.configurationRightSection
  > div.dataCalendarWarpper
  > div.dataStartingCalender
  > div
  > div
  > div
  > div
  > div
  > div.SingleDatePicker_picker.SingleDatePicker_picker_1.SingleDatePicker_picker__directionLeft.SingleDatePicker_picker__directionLeft_2 {
  box-shadow: 0px 8px 22px rgba(27, 31, 35, 0.07) !important;
  border-radius: 24px !important;
}
#root
  > div.mainContainer
  > div:nth-child(2)
  > div
  > div.configurationRightSection
  > div.dataCalendarWarpper
  > div.dataEndingCalender
  > div
  > div
  > div
  > div
  > div
  > div.SingleDatePicker_picker.SingleDatePicker_picker_1.SingleDatePicker_picker__directionLeft.SingleDatePicker_picker__directionLeft_2 {
  box-shadow: 0px 8px 22px rgba(27, 31, 35, 0.07) !important;
  border-radius: 24px !important;
}
.dataFilterPointsHeading {
  display: flex;
  /* background-color: red; */
  margin-left: 24px;
  margin-top: 40px;
  margin-right: 30px;
  justify-content: space-between;
}
.dataTooltipHeader {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #3b4a57;
}
.dataTooltip1stBody {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #3b4a57;
  /* margin-left: 24px; */
  margin-top: 5px;
  line-height: 20px;
}

#dataTooltip > div {
  width: 325px !important;
  padding: 24px 24px 24px 24px !important;
}
#postiDataTooltip {
  /* background-color: white !important;
  width: 400px !important;
  box-shadow: 0px 15px 22px rgba(0, 0, 0, 0.06), 0px 3px 8px rgba(0, 0, 0, 0.08),
    0px 1px 1px rgba(0, 0, 0, 0.08), 0px 3px 1px rgba(0, 0, 0, 0.04) !important;
  border-radius: 24px !important; */
  width: 325px !important;
  /* height: 255.41px !important; */
  background: #ffffff !important;
  box-shadow: 0px 15px 22px rgba(0, 0, 0, 0.06), 0px 3px 8px rgba(0, 0, 0, 0.08),
    0px 1px 1px rgba(0, 0, 0, 0.08), 0px 3px 1px rgba(0, 0, 0, 0.04) !important;
  border-radius: 24px !important;
  padding: 24px 24px 24px 24px !important;
}
#postiDataTooltip > div > div {
  display: none !important;
}
.dataFilterPointsBody {
  margin-top: 15px;
}
.dataFilterPointsRadioContainer {
  display: flex;
  align-items: center;
  margin-left: 26px;
  margin-bottom: 5px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #3b4a57;
}
.parabolaImageWrapper {
  width: 100%;
  margin-left: 26px;
  margin-top: 45px;
  margin-bottom: 9vh;
  padding-right: 6vh;
}
.parabolaImageHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #73808c;
}
.dataFilterFooter {
  height: 8.3vh;
  background-color: white;
  position: fixed;
  bottom: 0;
  width: 27.5%;
  padding-left: 24px;
  padding-right: 24px;
  /* padding-bottom: 20px; */
}
.dataFilterFooterBody {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 8vh;
}
.dataFilterResetButton {
  margin-right: 4%;
  border: none;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #0051c2;
  cursor: pointer;
  background-color: white;
}
.dataFilterApplyButton {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2px;
  margin-right: 10px;
}
.parabolaCss {
  width: 100% !important;
  height: 100% !important;
}

#postiDataTooltip::before {
  display: none !important;
}
.mapAreaCss {
  cursor: pointer;
}
