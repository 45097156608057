.mood {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #1e3264;
  text-align: center;
  margin: 61px 0px;
}
.notifications {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #3b4a57;
}

.no-avatar {
  margin-right: 19px;
  width: 32px;
  height: 32px;
  background-color: #c2c9d1;
  border-radius: 100px;
}

.main-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8.6px;
  margin-top: 19px;
}
.todaysLog {
  /* background-color: red; */
  height: 300px !important;
  padding-top: 30px !important;
}
.mainREC {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 15px;
  align-items: center;
}
