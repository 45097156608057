.wholeLogWrapper {
  display: flex;
  justify-content: space-between;
  height: 100%;
}
::placeholder {
  font-family: Montserrat !important;
  font-style: normal !important;
  font-weight: 600 !important;
  color: #3b4a57 !important;
}
.leftSideLog {
  width: 4953.813%;
  background-color: #fff;
  overflow-y: scroll;
}
.rightSideLog {
  width: 2439.938%;
  background-color: #fff;
  overflow-y: scroll;
}
.leftSideLogHeader {
  padding-left: 24px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 74px;
  color: #1e3264;
  background-color: #ffa894;
  height: 74px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: 56.2%;
  z-index: 3;
}
.rightSideLogFilterHeader {
  padding-left: 15px;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 20px;
  line-height: 74px;
  color: #1e3264;
  background: #d9bfad;
  height: 74px;
}
.rightSideLogFilterContainer {
  margin-top: 34.47px;
}
.searchBarLog {
  margin: 8px;
  margin-top: 11px;
  margin-right: 23.68px;
  width: 300px;
}
/* To change the css of left side log search bar */
div.leftSideLogHeader > div > div {
  background: #ffffff;
  mix-blend-mode: normal;
  /* box-shadow: inset 0px -2px 0px #c28479; */
  /* box-shadow: inset 0px -2px 0px #c28479; */
  border-radius: 16px;
}

/* To change the searchBar search ICON attribute */
div.leftSideLogHeader > div > div > svg {
  width: 20.55px;
  margin-left: 10px;
}

.typeButton,
.dateButton,
.actionButton,
.peopleBadgeButton {
  margin-top: 18.65px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1px;
  color: #1e3264;
  border: none;
  cursor: pointer;
  background-color: white;
}
.typeButton {
  margin-left: 24.36px;
}
.peopleBadgeButton {
  margin-right: 45px;
}
.actionButton {
  margin-right: 250px;
}
.dateButton {
  margin-right: 100px;
}
.line {
  width: 113.336vh;
  border: 1px solid #000000;
}

.columnTechnique {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  letter-spacing: 0.1px;
  color: #1e3264;
  border-bottom: 1px solid black !important;
  background-color: white !important;
  text-align: center;
}

/* To remove the border from datatable */
.p-datatable .p-datatable-tbody > tr > td {
  border: none !important;
}
.columnType {
  text-align: center !important;
}
.columnDate {
  text-align: center !important;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  letter-spacing: 0.1px;
  color: #1e3264;
  white-space: nowrap;
}

/* To change the CSS of header of Datatable */
div.datatableContainer > div > div > table > thead > tr > th.p-sortable-column {
  background-color: white;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  letter-spacing: 0.1px;
  color: #1e3264;
  border-bottom: 1px solid #000000;
}

.columnAction {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  letter-spacing: 0.1px;
  color: #1e3264;
}
/* To change the arrow Image in filter of datatable */
.pi-sort-alt:before,
.pi-sort-amount-down:before,
.pi-sort-amount-up-alt:before {
  content: url('../../images/arrow.png') !important;
}
.hourSecondCss {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  letter-spacing: 0.1px;
  color: #1e3264;
  margin-left: 5%;
}
.peopleBadgeAvatar {
  border: 1.5px solid orange;
}
/* To change the width of whole datatable */
div.leftSideLogContainer > div > div > div {
  margin-left: 1%;
  margin-right: 1%;
}
.leftSideLogFooter {
  height: 8vh;
  bottom: 0;
  width: 55.5%;
  position: fixed;
  background-color: white;
}
.leftSideLogFooterLine {
  border: 1px solid #c2c9d1;
  margin-left: 2%;
  margin-right: 1%;
  margin-top: -3px;
  background-color: #c2c9d1;
}
.leftSideLogFooterResetButton {
  float: right;
  margin-right: 5%;
  margin-top: 1%;
  background-color: white;
  border: none;
  cursor: pointer;
  color: #0051c2;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  height: 50%;
  width: 10%;
}
.leftSideLogFooterResetButtonDisable {
  float: right;
  margin-right: 5%;
  margin-top: 0.5%;
  background-color: white;
  border: none;
  cursor: pointer;
  color: #0051c2;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  height: 50%;
  width: 10%;
  pointer-events: none;
  opacity: 0.5;
}
/* To change the new Avatar position */
div.p-datatable.p-component
  > div
  > table
  > tbody
  > tr
  > td.columnTechnique
  > div {
  margin-left: 25%;
}
.dateData {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: 0.1px;

  color: #1e3264;
}
/* To change the CSS of Date column Data */
div.p-datatable.p-component > div > table > tbody > tr > td > span {
  margin-left: -21px !important;
}
.hourmm {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: 0.1px;

  color: #1e3264;
}
/* To align type elements to left side */
div.p-datatable.p-component > div > table > tbody > tr > td.columnType {
  padding-right: 2vh;
}

div.p-datatable.p-component > div > table > tbody > tr > td {
  padding-bottom: 0%;
}
.avatarWrapper {
  /* background-color: red; */
  margin-top: -1vh;
}
/* To change the People/Badge css */
div.leftSideLogContainer
  > div
  > div.p-datatable.p-component
  > div
  > table
  > thead
  > tr
  > th.p-sortable-disabled.columnTechnique {
  padding-left: 0vh;
}

#applyButton > span {
  padding: 12px 24px;
}
#root
  > div.mainContainer
  > div:nth-child(2)
  > div
  > div.leftSideLog
  > div.leftSideLogContainer
  > div
  > div.p-datatable.p-component
  > div
  > table
  > thead
  > tr
  > th.p-sortable-column.columnType {
  padding-left: 10px;
}
#root
  > div.mainContainer
  > div:nth-child(2)
  > div
  > div.leftSideLog
  > div.leftSideLogContainer
  > div
  > div.p-datatable.p-component
  > div
  > table
  > tbody
  > tr
  > td.columnType {
  padding-right: 31px !important;
}
#root
  > div.mainContainer
  > div:nth-child(2)
  > div
  > div.leftSideLog
  > div.leftSideLogContainer
  > div
  > div.p-datatable.p-component
  > div
  > table
  > tbody
  > tr
  > td.columnAction {
  padding-left: 16px !important;
}
#root
  > div.mainContainer
  > div:nth-child(2)
  > div
  > div.leftSideLog
  > div.leftSideLogContainer
  > div
  > div.p-datatable.p-component
  > div
  > table
  > tbody
  > tr:nth-child(1)
  > td.columnTechnique {
  padding-top: 16px !important;
}
.datatableContainer {
  margin-top: 70px !important;
}
.logNameInitial{
  margin-left: 17px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1px;
  color: #1E3264;
}
.logTooltipHeader{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  color: #000000;
}
.logTooltipBody{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 154%;
  color: #3B4A57;
}
#postiLogTooltip {
  background-color: white !important;
  width: 287px !important;
  box-shadow: 0px 15px 22px rgba(0, 0, 0, 0.06), 0px 3px 8px rgba(0, 0, 0, 0.08),
    0px 1px 1px rgba(0, 0, 0, 0.08), 0px 3px 1px rgba(0, 0, 0, 0.04) !important;
  border-radius: 24px !important;
  padding: 24px 24px 24px 24px !important;
}
#postiLogTooltip > div > div {
  display: none !important;
}
#postiLogTooltip::before {
  display: none !important;
}