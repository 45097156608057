.todaysWeatherHeading {
  padding-left: 24px;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 18px;
  line-height: 74px;
  color: #1e3264;
  background-color: #d9bfad;
  height: 74px;
}

.weatherScreenRightSectionFooter {
  display: flex;
  flex-direction: column;
  margin: 0px 1%;
  position: fixed;
  bottom: 0;
  width: 26%;
  background-color: #fff;
}

.notificationLogText {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3b4a57;
  padding-left: 24px;
  padding-bottom: 18px;
}

.notificationLogBadgeName {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1px;
  color: #1e3264;
  margin-left: 16px;
}

.timeTextNotificationLog {
  font-family: Montserrat;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1px;
  color: #1e3264;
}
