.editTeamContainer {
  /* width: 50.3%; */
  background-color: #f6f7f9;
  height: 100%;
  /* overflow-y: auto; */
  display: flex;
  justify-content: space-between;
}

.editTeamLeftSection {
  width: 62.7%;
  background-color: #fff;
  overflow-y: auto;
}

.editTeamHeaderLeftContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #a4dedf;
  height: 74px;
}
.edit_info_text {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  margin-top: 34px;
}

.editTeam-heading {
  padding-left: 32px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 32px;
  color: #1e3264;
}

.editTeamRightSection {
  width: 36%;
  background-color: #fff;
  overflow-y: auto;
  /* height: 500px; */
}

.how-to-heading {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #3b4a57;
  margin-top: 33.5px;
  margin-left: 32px;
}

.how-to-desc {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: #3b4a57;
  margin-left: 32px;
  margin-top: 18px;
}

#edit-team .p-dialog-header {
  display: none !important;
}

#edit-team_content {
  padding: 0px !important;
  border-radius: 16px;
}
#edit-team_content + div {
  display: none;
}

#edit-team [role='tablist'] {
  height: 74px;
  margin-bottom: 0 !important;
}

#editTeam-avatar {
  width: 82px;
  height: 82px;
  background-color: white;
  border-radius: 100px;
  position: relative;
  top: 30px;
  border: 6px solid #a4dedf;
  right: 39px;
  box-shadow: -2px -5px 5px rgba(27, 31, 35, 0.05);
}

#leftside-border {
  border: 1px solid #c2c9d1;
  margin-left: 32px;
  margin-right: 32px;
  position: fixed;
  bottom: 20vh;
  width: 45.7%;
}
#load-screen .p-dialog-header {
  display: none !important;
}

#load-screen_content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px !important;
  border-radius: 16px;
  background-color: transparent;
}
.editTeamButton {
  display: flex;
  justify-content: flex-end;
  height: 10vh;
  align-items: center;
  margin-left: 32px;
  margin-right: 32px;
  overflow: hidden;
}
