.messageScreenHeaderContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 74px;
  padding: 0 24px;
  padding-right: 35px;
  width: 100%;
}

.messageHeader {
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  font-family: 'Montserrat';
  font-weight: bold;
  font-size: 25px;
  line-height: 44px;
  color: #1e3264;
  width: 75%;
  justify-content: space-between;
}

.messageHeaderText {
  font-family: 'Montserrat';
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1px;
  color: #1e3264;
  padding-right: 1%;
}

.messageHeaderText1 {
  font-family: 'Montserrat';
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1px;
  color: #1e3264;
  padding-right: 2%;
  padding-left: 8%;
}

.messageContainer {
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}
.messageLeftSectionRoles {
  width: 100%;
  background-color: #fff;
  overflow-y: hidden;
  background: url('../../images/PECfox.png') no-repeat right top 135px, #fff;
  background-size: 700px;
  background-position-x: 95%;
}
.messageLeftSection {
  width: 66.7%;
  background-color: #fff;
  overflow-y: hidden;
  background: url('../../images/fox.png') no-repeat right top 135px, #fff;
}
.homepageFooterContainerRoles {
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 8px;
  position: fixed;
  bottom: 0;
  width: 82%;
  background-color: #fff;
  justify-content: center;
  z-index: 1;
}
.peopleRightSection {
  width: 33.3%;
  background-color: #fff;
  overflow-y: scroll;
}

.peopleTableHeader {
  display: flex;
  align-items: center;
  margin-left: 24px;
}

.messengerName {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1px;
  color: #1e3264;
  margin-left: 25px;
  cursor: pointer;
}
.messengerName1 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 650;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1px;
  color: #1e3264;
  margin-left: 25px;
  cursor: pointer;
}
.peopleDetails,
.peopleDetailsPEC {
  font-family: 'Montserrat';
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: #3b4a57;
}

.peopleScreenPagination {
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #73808c;
  padding: 0px 50px;
}

.peopleFooterTextEnable {
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  padding: 0 24px;
  color: #0051c2;
  cursor: pointer;
}

.peopleFooterTextDisable {
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  padding: 0 24px;
  color: #a7a7a7;
}
.avatarhover:hover {
  cursor: pointer;
}
.avatarhover {
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2));
}

#deletePeople {
  background-color: #bd2841;
}

#deletePeople::before {
  background-color: #bd2841;
}

#deletePeople > span {
  padding: 12px 24px;
}

.senderTableHeading1 {
  display: flex;
  align-items: center;
  width: 25%;
}
.senderTableHeading1SuperAdmin {
  display: flex;
  align-items: center;
  width: 22%;
}
.costCenterTableHeading1SuperAdmin {
  display: flex;
  align-items: center;
  width: 18%;
}
.costCenterBody1SuperAdmin {
  display: flex;
  align-items: center;
  width: 100%;
}
.subjectTableHeading1 {
  display: flex;
  align-items: center;
  width: 18%;
}
.subjectTableHeading1superAdmin {
  display: flex;
  align-items: center;
  width: 13%;
}
.subjectTableHeading2 {
  display: flex;
  align-items: center;
  width: 44%;
}
.subjectTableHeading2SuperAdmin {
  display: flex;
  align-items: center;
  width: 36%;
}
.ccNameSentboxSuperAdmin {
  display: flex;
  align-items: center;
  width: 16%;
}
.ccNameSentboxSuperAdmin1 {
  display: flex;
  align-items: center;
  width: 13%;
}
.ccNameSentboxBodySuperAdmin {
  display: flex;
  align-items: center;
  width: 100%;
}

.subjectTableHeading21 {
  display: flex;
  align-items: center;
  width: 62%;
}
.subjectTableHeading21SuperAdmin {
  display: flex;
  align-items: center;
  width: 47%;
}
.subjectContentHeading1 {
  display: flex;
  align-items: center;
}
.exceptionalRewardsTableHeading1 {
  display: flex;
  align-items: center;
  width: 13%;
  padding-left: 6px;
}
.messageSubjectPEC {
  width: 18%;
  margin-top: 10px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1px;
  color: #1e3264;
  cursor: pointer;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis !important; */
}
.messageSubjectPECSuperAdmin {
  width: 13%;
  margin-top: 10px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1px;
  color: #1e3264;
  cursor: pointer;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis !important; */
}
.messageBodyPEC {
  width: 44%;
  margin-top: 10px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1px;
  color: #1e3264;
}
.messageBodyPECSuperAdmin {
  width: 36%;
  margin-top: 10px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1px;
  color: #1e3264;
}
.messageBodyPEC1 {
  width: 62%;
  margin-top: 10px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1px;
  color: #1e3264;
}
.messageBodyPEC1SuperAdmin {
  width:47%;
  margin-top: 10px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1px;
  color: #1e3264;
}
.messageBodyPEC2 {
  width: 62%;
  margin-top: 10px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 650;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1px;
  color: #1e3264;
}
.messageBodyHide {
  width: 95% !important;
  white-space: nowrap;
  overflow: hidden;
  /* text-overflow: "----"; */
  text-overflow: ellipsis !important;
}
.messageSubjectHide {
  width: 95%;
  white-space: nowrap;
  overflow: hidden;
  /* text-overflow: "----"; */
  text-overflow: ellipsis !important;
}
.messageDatePEC {
  width: 13%;
  margin-top: 15px;
  display: flex;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1px;
  color: #1e3264;
  display: flex;
  cursor: pointer;
}
.messageDatePEC1 {
  width: 13%;
  margin-top: 15px;
  display: flex;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 650;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1px;
  color: #1e3264;
  display: flex;
  cursor: pointer;
}
#root > div.mainContainer > div > div > div > div > div > div > div.peopleBadgesPEC > div > div {
  margin-left: 10px;
}

#root
  > div.mainContainer
  > div
  > div
  > div
  > div
  > div
  > div
  > div.peopleBadgesPEC
  > div
  > div:hover {
  border: 3px solid white;
  outline: 3px solid #edbdbd;
}
.toolTipHeader {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  color: #000000;
  margin-top: 16px;
  margin-left: 23px;
  margin-right: 23px;
}
.toolTipBody {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 154%;
  margin-top: 5px;
  margin-left: 23px;
  margin-bottom: 16px;
  color: #3b4a57;
}
.MuiTooltip-tooltip {
  background-color: #ffffff !important;
  box-shadow: 0px 15px 22px rgba(0, 0, 0, 0.06), 0px 3px 8px rgba(0, 0, 0, 0.08),
    0px 1px 1px rgba(0, 0, 0, 0.08), 0px 3px 1px rgba(0, 0, 0, 0.04) !important;
  border-radius: 18px !important;
  width: 265px !important;
}
.peopleAvatarWrapper {
  margin-left: 10px;
}
.disablePreNextArrow {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}
.editMessage-tabStyle {
  box-shadow: none !important;
  cursor: pointer;
}
.editMessage-tabStyle1 {
  box-shadow: none !important;
  cursor: pointer;
}
.messageFooterSelectButton {
  float: right;
  margin-top: 1%;
  margin-bottom: 1%;
  background-color: white;
  border: none;
  cursor: pointer;
  color: #0051c2;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  height: 50%;
}
.messageFooterSelectButton1 {
  float: right;
  margin-top: 1%;
  margin-bottom: 1%;
  background-color: white;
  border: none;
  cursor: pointer;
  color: #0051c2;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  height: 50%;
}
.messageFooterSelectButtonDisable {
  float: right;
  border: none;
  cursor: pointer;
  color: #a7a7a7;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  height: 50%;
  pointer-events: none;
  background-color: white;
}
.messageFooterSelectButtonDisable1 {
  float: right;
  background-color: white;
  border: none;
  cursor: pointer;
  color: #a7a7a7;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  height: 50%;
  pointer-events: none;
}
.messageArchiveEnable {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
  color: #0051c2;
}
.messageArchiveDisable {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #a7a7a7;
  pointer-events: none;
}
.exceptionalRewardsTableHeading2 {
  display: flex;
  align-items: center;
  width: 50%;
  margin-left: 0.5%;
}
.messageByPEC {
  margin-top: 15px;
  display: flex;
  width: 140px;
  height: 17px;
  left: 1700.25px;
  top: 222.02px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1px;
  color: #1e3264;
}
#dateTooltip1 > div {
  width: 287px !important;
  height: 75.25 !important;
  padding: 18px 18px 18px 18px !important;
}
#dateTooltip2 > div {
  width: fit-content !important;
  height: 246px !important;
  padding: 18px 18px 18px 18px !important;
}
.dateTooltipHeader3 {
  /* width: 251.11px;
  height: 20px; */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  color: #000000;
  /* padding-top: 4px; */
}
.dateTooltipHeader {
  /* width: 251.11px;
  height: 20px; */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  color: #000000;
  line-break: anywhere;
}
.dateTooltip1stBody3 {
  /* width: 240.86px;
  height: 20px; */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 154%;
  color: #3b4a57;
  padding-top: 6px;
}
.dateTooltip1stBody {
  /* width: 240.86px;
  height: 20px; */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 154%;
  color: #3b4a57;
  line-break: anywhere;
}
.GraphicHolder {
  margin-top: 10px;
  margin-bottom: 10px;
}
.p-avatar-group .p-avatar {
  border: 3px solid white !important;
}
#id-tab1 > div:nth-child(3) > div > div > div > div.avatarhover > div > div > div {
  outline: 3px solid #ffa894 !important;
}
.p-avatar-text {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.0714286px;
  color: #ffffff;
}
.checkboxBorder {
  padding-top: 15px !important;
  overflow-y: scroll !important;
  height: calc(92vh - 115px) !important;
  padding-bottom: 8vh !important;
  padding-left: 24px !important;
}
.checkboxInnerBorder1 {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  padding-bottom: 10px !important;
  width: 100% !important;
}
.checkboxInnerBorder2 {
  display: flex !important;
  align-items: center !important;
  width: 25% !important;
}
.checkboxInnerBorder2SuperAdmin {
  display: flex !important;
  align-items: center !important;
  width: 22% !important;
}
.costCenter2SuperAdmin {
  display: flex !important;
  align-items: center !important;
  width: 18% !important;
}
.costCenter2SentBoxSuperAdmin{
  display: flex !important;
  align-items: center !important;
  width: 16% !important;
}
.costCenter2SentBoxSuperAdmin1{
  display: flex !important;
  align-items: center !important;
  width: 13% !important;
}
.commonOuterFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 8vh;
  width: 100%;
}
.commonInnerFooter {
  display: flex;
  align-items: center;
  width: 60%;
  justify-content: flex-end;
  gap: 20px;
}
.primeFirstAvatar,
.primeSecondAvatar,
.primeCountAvatar {
  border: 3px solid white !important;
  outline: 3px solid #ffa894 !important;
}
#dateTooltip3 > div {
  width: fit-content !important;
  min-height: 146px !important;
  padding: 18px 18px 18px 18px !important;
}
.primeCountAvatar {
  z-index: 3;
}
.primeFirstAvatar {
  z-index: 2;
  margin-left: -42px !important;
}
.primeSecondAvatar {
  margin-left: -42px !important;
}
#id-tab1 > div:nth-child(3) > div > div > div:nth-child(1) > div.avatarhover > div {
  width: 60px !important;
}
#id-tab1 > div:nth-child(3) > div > div > div:nth-child(1) {
  margin-bottom: -10px;
  height: 60px !important;
}

.primeSingleAvatar {
  outline: 3px solid #ffa894 !important;
  border: 3px solid white !important;
}
#id-tab2 > div:nth-child(3) > div > div > div:nth-child(1) > div.avatarhover > div {
  width: 60px !important;
}
#id-tab2 > div:nth-child(3) > div > div > div:nth-child(1) {
  margin-bottom: -10px;
  height: 60px !important;
}

#postiMainDataTooltipx {
  display: flex !important;
  flex-direction: column !important;
  background-color: white !important;
  width: 335px !important;
  height: auto !important;
  box-shadow: 0px 15px 22px rgba(0, 0, 0, 0.06), 0px 3px 8px rgba(0, 0, 0, 0.08),
    0px 1px 1px rgba(0, 0, 0, 0.08), 0px 3px 1px rgba(0, 0, 0, 0.04) !important;
  border-radius: 24px !important;
  padding: 24px 24px 24px 24px !important;
}
#postiMainDataTooltipx > div > div {
  display: none !important;
}
#postiMainDataTooltipx::before {
  display: none !important;
}
#root
  > div.mainContainer
  > div:nth-child(2)
  > div
  > div
  > div.messageScreenHeaderContainer
  > div:nth-child(2)
  > div
  > div {
  min-height: 0rem !important;
}
.newMsgIndicator {
  width: 41px;
  height: 17px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  letter-spacing: 0.1px;
  color: #008a00;
}
.arrowCSS{
  margin-top: -15px !important;
  position: absolute !important;
  z-index: 500 !important;
}
