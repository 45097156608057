.apiDialogHeader {
    position: absolute;
    width: 477.13px;
    height: 71.62px;
    background: #EDBDBD;;
    border-radius: 16px 16px 0px 0px;
  }
  .apiDialogBody {
    position: absolute;
    width: 337px;
    height: 28px;
    left: 32px;
    top: 22.81px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 155.6%;
    color: #1e3264;
  }
  .editDialogWrapper {
    position: absolute;
    width: 477.13px;
    height: 330.87px;
    left: 35%;
    top: 30%;
    background: #ffffff;
    box-shadow: 0px 8px 22px rgba(27, 31, 35, 0.07);
    border-radius: 16px;
    z-index: 2;
    margin-left: -220px !important;
    margin-top: -160px !important;
  }
  .editDialogText {
    position: absolute;
    height: 112px;
    left: 32px;
    right: 76.01px;
    top: 103.25px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 155.6%;
    color: #3b4a57;
  }
  .editfooterline {
    position: absolute;
    width: 413.02px;
    height: 0px;
    left: 31.99px;
    bottom: 99.9px;
    border: 1px solid #c2c9d1;
    margin-bottom: -23px;
  }
  .editDialogOkButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    position: absolute;
    width: 72px;
    height: 39px;
    left: 365.01px;
    bottom: 25.72px;
    padding-top: 16.18px;
    background: #0051c2;
    border-radius: 40px;
  }
  