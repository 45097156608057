.teamEditHeaderRightContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #d9bfad;
  height: 74px;
}

.TeamNameHeading {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 20px */
  margin-top: 32px;
  margin-bottom: 18px;
  display: flex;
  align-items: center;

  /* Neutrals / Network Gray */

  color: #3b4a57;
}

.teamEditFooterContainer {
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.teamEditButtonContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

.tabs {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #3b4a57;
}

.cancel-button {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #0051c2;
  margin-right: 24px;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

.image-name {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #3b4a57;
  padding-left: 13px;
}

.placeholder {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* display: flex; */
  align-items: center;
  text-align: center;
  color: #3b4a57;
}

#teamEdit-nextButton > span {
  padding: 12px 24px;
  /* height: 40%; */
}

.editTeam-tabStyle {
  margin-left: 32px;
  box-shadow: none !important;
  cursor: pointer;
}

#name-buttons {
  position: fixed;
  bottom: 10vh;
  height: 10vh;
  width: 24%;
  margin-left: 32px;
}

.member-name {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1px;
  color: #1e3264;
  padding-left: 1rem;
}
.nameContentContainer {
  padding: 0px 32px 2px;
  overflow-y: auto;
  margin-bottom: 15vh;
}

#nameTabActive {
  box-shadow: none;
  color: #3b4a57;
  font-size: 16px;
  font-weight: 600;
  font-family: Montserrat;
}

#nameTabInActive {
  color: #3b4a57;
  font-size: 16px;
  font-weight: 400;
  font-family: Montserrat;
}
