/* #champs-dialog.p-dialog-header {
  display: none !important;
}
*/
#champs-dialog_content {
  padding: 0px !important;
  border-radius: 16px;
}

/* #champs-dialog [role='tablist'] {
  height: 74px;
  margin-bottom: 0 !important;
} */

#champs-dialog .p-dialog-header {
  display: none !important;
}

/* #champs-dialog .p-dialog .p-dialog-content {
  padding: 0px !important;
} */

#champs-avatar {
  width: 82px;
  height: 82px;
  background-color: white;
  border-radius: 100px;
  position: relative;
  top: 30px;
  border: 6px solid #a4dedf;
  right: 39px;
  box-shadow: -2px -5px 5px rgba(27, 31, 35, 0.05);
}

.teamInfo {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #3b4a57;
}

.table-header {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: #3b4a57;
}

.table-info {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #3b4a57;
}

.teamMembers {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #3b4a57;
  margin-top: 35px;
}

.memberName {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #1e3264;
  margin-left: 15px;
}

#champs-buttons {
  position: fixed;
  bottom: 5vh;
  height: 10vh;
  width: 35vw;
  margin-left: 32px;
}

.champs-heading {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 32px;
  color: #1e3264;
  margin-left: 32px;
}
.teamNameContainer {
  background-color: #a4dedf;
  height: 71px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
