.wholeConfigurationContainer {
  display: flex;
  justify-content: space-between;
  /* background-color: red; */
  height: 100%;
}
.configurationLeftSection {
  width: 66.7%;
  background-color: white;
  overflow-y: auto;
}
.configurationRightSection {
  width: 33.3%;
  background-color: white;
  overflow-y: auto;
}
.configurationLeftSectionHeaderContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffa894;
  height: 74px;
  padding: 0 24px;
  z-index: 3;
  position: fixed;
  width: 55.86%;
}
.configurationHeader {
  font-family: Montserrat;
  font-weight: bold;
  font-size: 25px;
  line-height: 32px;
  color: #1e3264;
}
#headerButtons > div.p-button.p-component {
  height: 44px;
}
#headerButtons > div.p-button.p-component.p-highlight {
  background-color: #29545c;
  color: white;
}
#headerButtons > div.p-button.p-component.p-highlight[aria-label='Locations'],
#headerButtons > div:nth-child(2) {
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
}
#headerButtons > div.p-button.p-component.p-highlight[aria-label='Badges'],
#headerButtons > div:nth-child(1) {
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
}
.badgesTableContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
}
.tableHeadings {
  display: flex;
  padding-left: 25px;
  padding-right: 25px;
  background-color: white;
  position: fixed;
  z-index: 3;
  width: 55.86%;
  margin-top: 74px;
}
.badgesHeading,
.daysHeading,
.slackHeading,
.targetHeading,
.pointsHeading,
.startHeading,
.endHeading,
.eligibilityHeading,
.locationsHeading {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  letter-spacing: 0.1px;
  color: #1e3264;
  /* border: 1px solid black; */
  word-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.badgesHeadingData,
.daysHeadingData,
.slackHeadingData,
.targetHeadingData,
.pointsHeadingData,
.startHeadingData,
.endHeadingData,
.eligibilityHeadingData,
.locationsHeadingData {
  /* display: flex; */
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  letter-spacing: 0.1px;
  color: #1e3264;
  /* border: 1px solid black; */
  word-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 50px;
  /* align-items: center; */
  border-bottom: 1px solid #eaedf1;
  padding-top: 18px;
}
.badgesHeading {
  width: 27%;
}
.badgesHeadingData {
  width: 27%;
}
.daysHeading,
.slackHeading,
.targetHeading,
.pointsHeading {
  width: 7%;
}
.daysHeadingData,
.slackHeadingData,
.targetHeadingData,
.pointsHeadingData {
  width: 7%;
}
.startHeading,
.endHeading {
  width: 18%;
}
.startHeadingData,
.endHeadingData {
  width: 18%;
}
.eligibilityHeading {
  width: 9%;
}
.eligibilityHeadingData {
  width: 9%;
}
.locationsHeading {
  width: 27%;
}
.locationsHeadingData {
  width: 27%;
}
.tableBody {
  display: flex;
  flex-direction: column;
  padding-left: 25px;
  padding-right: 25px;
  margin-bottom: 10vh;
  margin-top: -10px;
}
.badgesHeadingData {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #3b4a57;
}
.uniqueBadgeHeading,
.uniqueBadgeHeading2 {
  display: flex;
  background-color: #f6f7f9;
  height: 33px;
  align-items: center;
  margin-bottom: -15px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #000000;
  justify-content: space-between;
  padding: 0 1% 0 1%;
  cursor: pointer;
  margin-top: 130px;
}
.uniqueBadgeHeading2 {
  border-top: 1px solid #262c30;
  z-index: 1;
}
.ellipseImage {
  margin-right: 5%;
}
.uniqueBadgeHeading {
  z-index: 1;
  position: relative;
  margin-top: 17px;
}
.configurationFooter {
  height: 8.3vh;
  bottom: 0;
  width: 55.5%;
  position: fixed;
  background-color: white;
  z-index: 2;
}
.configurationFooterLine {
  border: 1px solid #c2c9d1;
  margin-left: 2%;
  margin-right: 1%;
  margin-top: 0px;
  background-color: #c2c9d1;
}
.configurationFooterButtonContainer {
  /* background-color: red; */
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 2%;
  /* background-color: red; */
  padding-right: 1%;
}
.newConfigurationButton,
.newConfigurationEditButton,
.newConfigurationEditButtonDisable {
  border: none;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #0051c2;
  cursor: pointer;
  background-color: white;
}
.newConfigurationButton {
  white-space: nowrap;
  height: 50px;
  margin-top: 5px !important;
}
.newConfigurationEditButton,
.newConfigurationEditButtonDisable {
  margin-right: 50px;
}
.newConfigurationEditButtonDisable {
  color: #a7a7a7;
  pointer-events: pointer;
}
.newConfigurationDeleteButton {
  border: none;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #0051c2;
  cursor: pointer;
  background-color: white;
  margin-left: 4vh;
  margin-top: 8px;
}
.newConfigurationDeleteButtonDisable {
  border: none;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #a7a7a7;
  cursor: pointer;
  background-color: white;
  margin-left: 4vh;
  cursor: pointer;
  margin-top: 8px;
}
.checkboxImgBadgeDataContainer {
  word-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: -4px;
  /* display: flex;
    align-items: center; */
}
.badgesHeadingData {
  margin-top: 14px;
}
.configurationBadgesCheckbox,
.configurationLocationCheckbox {
  margin-left: 3px;
}
#deleteConfigurationDialog_content,
#deleteConfigurationInformationDialog_content {
  padding: 0;
  border-radius: 16px;
}
#deleteConfigurationDialog,
#deleteConfigurationInformationDialog {
  box-shadow: none;
}
.configDeleteDialogHeader {
  display: flex;
  align-items: center;
  background: #ffc226;
  border-radius: 16px 16px 0px 0px;
  height: 72px;
  padding-left: 32px;
}
.configDeleteDialogHeading {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 155.6%;
  color: #1e3264;
}
.configDeleteDialogBody {
  height: 160px;
  padding: 31px 76px 44px 32px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 155.6%;
  color: #3b4a57;
}
.configDeleteDialogFooter {
  height: 71px;
  border-top: 1px solid #c2c9d1;
  margin-left: 32px;
  margin-right: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
}
.configDeleteDialogFooterDeleteButton,
.configDeleteDialogFooterDeleteButton::before {
  background-color: #bd2841 !important;
  height: 50px;
}
.configDeleteDialogFooterCancelButton {
  border: none;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #0051c2;
  cursor: pointer;
  background-color: white;
}
.deleteConfigurationInformationDialogHeader {
  display: flex;
  align-items: center;
  height: 71px;
  background-color: #a4dedf;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 155.6%;
  /* identical to box height, or 28px */
  color: #1e3264;
  padding-left: 32px;
}
.deleteConfigurationInformationDialogBody {
  height: 260px;
  padding-left: 32px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 155.6%;
  color: #3b4a57;
  padding-top: 32px;
  padding-right: 30px;
}
.deleteConfigurationInformationDialogFooter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  height: 72px;
  width: 413px;
  border-top: 1px solid #c2c9d1;
}
.deleteInformationDialogFooterOkButton {
  height: 50px;
}
.onlyBadgeData {
  display: none !important;
}
.testt1 {
  height: 8vh;
  margin-top: -10px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.testt2 {
  height: 100%;
  display: flex;
  align-items: center;
}
.testt3 {
  height: 100%;
  display: flex;
  align-items: center;
}
@media (max-width: 1370px) {
  .tableHeadings {
    width: 54.86%;
  }

  .configurationLeftSectionHeaderContainer {
    width: 55.5%;
  }
}
.check {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #3b4a57;
}
.dataFilterHeadingQ {
  display: flex;
}
.mapHeadingHolder {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #3b4a57;
  padding-bottom: 25px;
  width: 500px !important;
}
.dataFilterHeading1 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #3b4a57;
  padding-bottom: 25px;
  width: 82%;
}
.dataFilterHeading2 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #3b4a57;
  padding-bottom: 25px;
  /* margin-left: 2%; */
  width: 64.5%;
}
.imageHolder1 {
  margin-left: 43%;
}
.imageHolder2 {
  margin-left: 48%;
}
.imageHolder3 {
  margin-left: 12%;
}
.legendsHeadingG1 {
  padding-top: 20px;
  padding-left: 25px;
  width: 18%;
  height: 14px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #1e3264;
}
.legendDis {
  padding-left: 8px;
  width: 129.01px;
  height: 12px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #1e3264;
}
.legendDis1 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  padding-left: 6px;
  margin-top: -2px;
  color: #3b4a57;
}
.legendDis3 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  padding-left: 10px;
  margin-top: 2px;
  color: #3b4a57;
}
.legendDis2 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  padding-left: 6px;
  margin-top: -2px;
  color: #3b4a57;
}
.legendDesc1 {
  padding-bottom: 4.5px;
}
.legendDesc2 {
  padding-top: 12px;
  padding-bottom: 4.5px;
}
.toolTipWrapper {
  justify-content: space-between;
  background-color: #ffffff;
  width: 236.58px;
  height: 68.81px;
  box-shadow: 0px 15px 22px rgba(0, 0, 0, 0.06), 0px 3px 8px rgba(0, 0, 0, 0.08),
    0px 1px 1px rgba(0, 0, 0, 0.08), 0px 3px 1px rgba(0, 0, 0, 0.04);
  border-radius: 24px;
}
.toolTipHeader {
  padding-top: 14px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #1e3264;
  width: 400px;
}
.toolTipHeader1 {
  padding-left: 25px;
  padding-top: 5px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #1e3264;
}
.mainWrapper {
  padding-top: 11px;
  width: 122px;
  height: 45px;
  background: #ffffff;
  box-shadow: 0px 15px 22px rgba(0, 0, 0, 0.06), 0px 3px 8px rgba(0, 0, 0, 0.08),
    0px 1px 1px rgba(0, 0, 0, 0.08), 0px 3px 1px rgba(0, 0, 0, 0.04);
  border-radius: 24px;
}
.peopleContainer2 {
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 10px;
}
.peopleContainer1 {
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 100px;
  margin-top: 4%;
}
.pointsColumn {
  display: flex;
  /* padding-left: 104px; */
  width: 150px;
  height: 22px;
  left: 592.69px;
  top: 1051.14px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  /* text-align: right; */
  letter-spacing: 0.1px;
  color: #1e3264;
  /* align-items: flex-end; */
  justify-content: end;
}
.pcsColumn {
  display: flex;
  /* padding-left: 104px; */
  /* width: 73px; */
  height: 22px;
  left: 592.69px;
  top: 1051.14px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: right;
  letter-spacing: 0.1px;
  color: #1e3264;
}
.points {
  width: 20px;
  padding-top: 4px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  letter-spacing: 0.1px;
  color: #1e3264;
}
.points1 {
  width: 20px;
  padding-top: 4px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  letter-spacing: 0.1px;
  color: #1e3264;
}
.peopleName1 {
  font-family: Montserrat;
  width: 75%;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1px;
  color: #1e3264;
}
.subHolder {
  margin-left: 48%;
  cursor: pointer;
}
.subHolder1 {
  margin-left: 48%;
  cursor: pointer;
}
#dataTooltip1 > div {
  width: 325% !important;
  /* height: 438px !important; */
  padding: 24px 24px 24px 24px !important;
}
.footerLegend1 {
  margin-top: 146px;
  width: 100.91px;
  height: 14.73px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  color: #73808c;
}
.memberHeading {
  padding-left: 75px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  color: #73808c;
}
.footerLegend2 {
  margin-top: 123px;
  width: 100.91px;
  height: 14.73px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  color: #73808c;
}
.footerLegend3 {
  margin-top: 62px;
  width: 100.91px;
  height: 14.73px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  color: #73808c;
}
.ovalHolder2 {
  margin-left: 5.4%;
  margin-bottom: 4%;
  border-radius: 50%;
  background-color: #ffa894;
  height: 39.79px;
  width: 39.79px;
  text-align: center;
  padding-top: 8px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #3b4a57;
}
.ovalHolder3 {
  margin-left: 5.7%;
  margin-bottom: 4%;
  border-radius: 50%;
  background-color: #ffa894;
  height: 39.79px;
  width: 39.79px;
  text-align: center;
  padding-top: 8px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #3b4a57;
}
.ovalHolder4 {
  margin-left: 5.2%;
  margin-bottom: 4%;
  border-radius: 50%;
  background-color: #ffa894;
  height: 39.79px;
  width: 39.79px;
  text-align: center;
  padding-top: 8px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #3b4a57;
}
.ovalHolder5 {
  margin-left: 5.7%;
  margin-bottom: 4%;
  border-radius: 50%;
  background-color: #ffa894;
  height: 39.79px;
  width: 39.79px;
  text-align: center;
  padding-top: 8px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #3b4a57;
}
.ovalHolder6 {
  margin-left: 5.7%;
  margin-bottom: 4%;
  border-radius: 50%;
  background-color: #ffa894;
  height: 39.79px;
  width: 39.79px;
  text-align: center;
  padding-top: 8px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #3b4a57;
}
.ovalHolder7 {
  margin-left: 5.4%;
  margin-bottom: 4%;
  border-radius: 50%;
  background-color: #ffa894;
  height: 39.79px;
  width: 39.79px;
  text-align: center;
  padding-top: 8px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #3b4a57;
}
.ovalHolder8 {
  margin-left: 5.4%;
  margin-bottom: 4%;
}
.numberHolder {
  z-index: 5;
  padding-left: -10px;
}

#postiMainDataTooltip {
  /* background-color: white !important;
  width: 400px !important;
  box-shadow: 0px 15px 22px rgba(0, 0, 0, 0.06), 0px 3px 8px rgba(0, 0, 0, 0.08),
    0px 1px 1px rgba(0, 0, 0, 0.08), 0px 3px 1px rgba(0, 0, 0, 0.04) !important;
  border-radius: 24px !important; */
  width: 325px !important;
  /* height: 338px !important; */
  background: #ffffff;
  box-shadow: 0px 15px 22px rgba(0, 0, 0, 0.06), 0px 3px 8px rgba(0, 0, 0, 0.08),
    0px 1px 1px rgba(0, 0, 0, 0.08), 0px 3px 1px rgba(0, 0, 0, 0.04);
  border-radius: 24px !important;
  padding: 24px 24px 24px 24px !important;
}
#postiMainDataTooltip > div > div {
  display: none !important;
}
#postiMainDataTooltip::before {
  display: none !important;
}
#postiMainDataTooltip1 {
  /* background-color: white !important;
  width: 400px !important;
  box-shadow: 0px 15px 22px rgba(0, 0, 0, 0.06), 0px 3px 8px rgba(0, 0, 0, 0.08),
    0px 1px 1px rgba(0, 0, 0, 0.08), 0px 3px 1px rgba(0, 0, 0, 0.04) !important;
  border-radius: 24px !important;
  padding: 24px 24px 24px 24px !important; */
  width: 325px !important;
  height: 236px !important;
  background: #ffffff !important;
  box-shadow: 0px 15px 22px rgba(0, 0, 0, 0.06), 0px 3px 8px rgba(0, 0, 0, 0.08),
    0px 1px 1px rgba(0, 0, 0, 0.08), 0px 3px 1px rgba(0, 0, 0, 0.04) !important;
  border-radius: 24px !important;
  padding: 24px 24px 24px 24px !important;
}
.dataTooltipHeader1 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #3b4a57;
}
.dataTooltip1stBody1 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #3b4a57;
}
#postiMainDataTooltip1 > div > div {
  display: none !important;
}
#postiMainDataTooltip1::before {
  display: none !important;
}
.rewardsCirleContainer {
  display: flex;
  /* background-color: red; */
  height: 45px;
  margin-left: 48px;
  margin-right: 10vw;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  margin-top: -10px;
}
.rewardsCirleContainerSingle {
  display: flex;
  /* background-color: red; */
  height: 45px;
  margin-left: 95px;
  margin-right: 26.5vh;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: -10px;
}
.ovalHolder1 {
  border-radius: 50%;
  background-color: #ffa894;
  height: 39.79px;
  width: 39.79px;
  text-align: center;
  padding-top: 11px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #3b4a57;
}
.rewardsCirleHeadingContainer {
  float: right;
  margin-top: -70px;
  margin-right: 16vh;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #73808c;
}
.rewardsCirleContainerHeading {
  margin-top: 6.2vh;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  color: #73808c;
}
.ptsByPeopleTooltipHeader {
  display: flex;
  justify-content: space-between;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  color: #000000;
  margin-bottom: 18px;
}
.ptsByPeopleTooltipBody {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ptsByPeopleTooltipNameImg {
  display: flex;
  align-items: center;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 154%;
  color: #3b4a57;
  gap: 10px;
}

.ptsByPeopleTooltipCountPts {
  display: flex;
  /* align-items: center;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 154%;
  color: #3B4A57;
  gap:10px */
  width: 50%;
}
.checking1 {
  width: 33%;
  text-align: end;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 154%;
  color: #3b4a57;
}
.checking2 {
  width: 33%;
  text-align: end;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 154%;
  color: #3b4a57;
  /* gap:10px */
}
.checking3{
  width: 33%;
  text-align: end;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 154%;
  color: #3b4a57;
  /* gap:10px */
}
.wrapper {
  position: relative;
  margin-bottom: 20px;
  box-shadow: 0px 15px 22px rgba(0, 0, 0, 0.06), 0px 3px 8px rgba(0, 0, 0, 0.08),
    0px 1px 1px rgba(0, 0, 0, 0.08), 0px 3px 1px rgba(0, 0, 0, 0.04);
  border-radius: 18px;
  /* padding: 20px; */
}
.tooltip {
  transform: none;
  /* margin: 50px;     */
  font-family: Montserrat;
  width: 75%;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1px;
  color: #1e3264;
}

.tooltip:hover > .tooltip-text,
.tooltip:hover > .wrapper {
  pointer-events: auto;
  opacity: 1;
}

.tooltip > .tooltip-text,
.tooltip > .wrapper {
  display: block;
  position: absolute;
  z-index: 6000;
  overflow: visible;
  /* padding: 5px 8px; */
  /* margin-top: -250px; */
  /* line-height: 16px; */
  border-radius: 18px;
  text-align: left;
  color: #fff;
  background: #ffff;
  /* background: grey; */
  pointer-events: none;
  opacity: 0;
  -o-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  margin-bottom: 20px;
}

/* Arrow */
.tooltip > .tooltip-text:before,
.tooltip > .wrapper:before {
  display: none;
  top: -5px;
  content: '';
  position: absolute;
  border: solid;
  border-color: rgba(0, 0, 0, 1) transparent;
  border-width: 0 0.5em 0.5em 0.5em;
  z-index: 6000;
  left: 20px;
  box-shadow: #a7a7a7;
}

/* Invisible area so you can hover over tooltip */
.tooltip > .tooltip-text:after,
.tooltip > .wrapper:after {
  top: -20px;
  content: ' ';
  display: block;
  height: 20px;
  position: absolute;
  width: 60px;
  /* left: 20px; */
}

.wrapper > .tooltip-text {
  overflow-y: auto;
  max-height: 250px;
  /* min-height: 250px; */
  display: block;
  padding: 20px;
  width: 461px;
}
.noPointsCSS {
  /* margin-top: 25%; */
  width: 311.42px;
  height: 20px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 154%;
  text-align: center;
  color: #73808c;
}
#root
  > div.mainContainer
  > div:nth-child(2)
  > div
  > div.configurationLeftSection
  > div.configurationFilter
  > div:nth-child(2)
  > div:nth-child(1)
  > div.recharts-responsive-container
  > div
  > svg
  > g.recharts-layer.recharts-cartesian-axis.recharts-xAxis.xAxis
  > g
  > g:nth-child(n) {
  font-family: 'Montserrat';
  font-size: 10px;
  font-weight: 500;
  line-height: 10px;
  letter-spacing: 0.02em;
}
#root
  > div.mainContainer
  > div:nth-child(2)
  > div
  > div.configurationLeftSection
  > div.configurationFilter
  > div:nth-child(2)
  > div:nth-child(1)
  > div.recharts-responsive-container
  > div
  > svg
  > g.recharts-layer.recharts-cartesian-axis.recharts-yAxis.yAxis
  > g
  > g:nth-child(n) {
  font-family: 'Montserrat';
  font-size: 10px;
  font-weight: 500;
  line-height: 10px;
  letter-spacing: 0.02em;
}
#root
  > div.mainContainer
  > div:nth-child(2)
  > div
  > div.configurationLeftSection
  > div.configurationFilter
  > div:nth-child(4)
  > div:nth-child(1)
  > div.recharts-responsive-container
  > div
  > svg
  > g.recharts-layer.recharts-cartesian-axis.recharts-xAxis.xAxis
  > g
  > g:nth-child(n) {
  font-family: 'Montserrat';
  font-size: 10px;
  font-weight: 500;
  line-height: 10px;
  letter-spacing: 0.02em;
}
#root
  > div.mainContainer
  > div:nth-child(2)
  > div
  > div.configurationLeftSection
  > div.configurationFilter
  > div:nth-child(4)
  > div:nth-child(1)
  > div.recharts-responsive-container
  > div
  > svg
  > g.recharts-layer.recharts-cartesian-axis.recharts-yAxis.yAxis
  > g
  > g:nth-child(n) {
  font-family: 'Montserrat';
  font-size: 10px;
  font-weight: 500;
  line-height: 10px;
  letter-spacing: 0.02em;
}
