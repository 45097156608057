.configurationRightSectionHeaderContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #d9bfad;
  height: 74px;
  padding: 0 24px;
}
.configurationFilterHeader {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #1e3264;
}
.configurationFilter {
  /* Dev changes 30px to 100px*/
  margin-top: 100px;
  padding-left: 25px;
  position: relative;
}
.badgesFilter,
.locationFilter,
.searchFilter {
  /* border: 1px solid black; */
  margin-top: 35px;
  padding-left: 25px;
}
.configurationFilterHeading,
.badgesFilterHeading,
.locationFilterHeading {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #3b4a57;
}
.configurationFilterBody,
.badgesFilterBody,
.locationFilterBody,
.searchFilterBody {
  margin-top: 22px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #3b4a57;
}
.checkboxAndBadgeNameContainer {
  margin-bottom: 8px;
}
.localRadioButton {
  margin-top: 8px;
}

.locationSearchBar {
  width: 80%;
  height: 72px;
}
.searchFilterBody {
  padding-left: 35px;
  padding-bottom: 9vh;
}
.searchCheckboxAndBadgeNameContainer {
  margin-bottom: 8px;
}
.filterFooter {
  height: 8.3vh;
  background-color: white;
  position: fixed;
  bottom: 0;
  width: 27.5%;
  padding-left: 24px;
  padding-right: 24px;
  /* padding-bottom: 20px; */
}
.filterFooterBody {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 8vh;
}
.configFilterResetButton {
  margin-right: 4%;
  border: none;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #0051c2;
  cursor: pointer;
  background-color: white;
}
.configFilterApplyButton {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2px;
  margin-right: 10px;
  height: 39px;
}
.searchFilter {
  margin-bottom: 8vh;
}
#root
  > div.mainContainer
  > div:nth-child(2)
  > div
  > div.configurationRightSection
  > div.searchFilter
  > div.locationSearchBar
  > div
  > div
  > label {
  color: #c2c9d1;
}
#root > div.mainContainer > div:nth-child(2) > div > div.configurationRightSection > div.filterFooter > div.filterFooterBody > button > span > span{
  font-size: 16px;
}