#newConfigurationDialog .p-dialog-content {
  padding: 0%;
  width: 562.82px;
  height: 819.28px;
  border-radius: 16px !important;
}

.newConfigurationHeader {
  display: flex;
  justify-content: space-between;
  padding-left: 24px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 74px;
  color: #1e3264;
  background-color: #ffa894;
  height: 70.75px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.newConfigurationContainer {
  padding: 32px;
}
.newConfigurationSubHeading {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #3b4a57;
  line-height: 20px;
}
.newConfigCategoryContainer {
  padding-top: 19px;
  padding-bottom: 12px;
  flex: 1;
  flex-direction: row;
}
.newConfigCategory {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: #3b4a57;
  line-height: 20px;
  flex: 1;
  flex-direction: column;
}
.newConfigDesc {
  width: 144px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #3b4a57;
  text-align: left;
}
.newConfigDesc1 {
  width: 144px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #0051c2;
  text-align: left;
}
.newConfigDescBrackets {
  width: 144px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: blue;
  text-align: left;
}
.oldValue {
  color: rgb(68, 58, 58);
  text-decoration: line-through;
}
.oldValue1 {
  color: rgb(68, 58, 58);
}
.check1 {
  color: blue;
}
.footerText {
  position: relative;
  width: 105px;
  height: 50px;
  border: none;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #0051c2;
  cursor: pointer;
  background-color: white;
  justify-content: center;
}
.descContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.descContainer2 {
  display: flex;
  flex-direction: row;
}
.span {
  width: 144px;
}
.startPublishApplyDateContainer {
  width: 124px;
}
.footerNewConfig {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 15px;
  margin-left: 200px;
}
.newConfigurationFooterLine {
  position: absolute;
  width: 520.82px;
  bottom: 69px;
  border: 1px solid #c2c9d1;
  margin-left: 4%;
  margin-right: 1%;
  background-color: #c2c9d1;
}
.footerTextCreateButton {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2px;
}
.footercancelButton {
  position: relative;
  width: 105px;
  height: 50px;
  border: none;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #0051c2;
  cursor: pointer;
  background-color: white;
  justify-content: center;
}