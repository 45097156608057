.edittabHeading {
  position: static;
  height: 20px;
  left: 0px;
  right: 45.21px;
  top: 18px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  display: flex;
  align-items: center;
  color: #3b4a57;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
  padding-bottom: 18px;
}
.editmain-div {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.editmain-div2 {
  display: flex;
  justify-content: space-evenly;
  margin-left: 30.29px;
}
.editheading {
  height: 20px;
  left: 0px;
  top: 0px;
  border-radius: nullpx;
  margin-top: 53.57px;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.editsubheading {
  height: 20px;
  width: 222.2936553955078px;
  left: 0px;
  top: 20px;
  border-radius: nullpx;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.editcontents {
  padding-left: 38.87px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 154%;
  padding-right: 33.89px;
  padding-top: 18px;
}
.editdatabox {
  height: 72px;
  width: 104px;
  border-radius: 16px;
  background: #f6f7f9;
  margin-top: 37.92px;
  margin-right: 34.19px;
  padding-top: 26px;
  padding-left: 32px;
  padding-bottom: 26px;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  box-shadow: 0px -2px 0px 0px #c2c9d1 inset;
  color: #0051c2;
  border-style: none;
}
.editdatabox1 {
  height: 72px;
  width: 104px;
  border-radius: 16px;
  background: #f6f7f9;
  margin-top: 5.34px;
  margin-right: 34.19px;
  padding-top: 26px;
  padding-left: 32px;
  padding-bottom: 26px;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  box-shadow: 0px -2px 0px 0px #c2c9d1 inset;
  color: #0051c2;
}
.editheading1 {
  height: 20px;
  /*width: 278.6419372558594px;*/
  left: 0px;
  top: 0px;
  border-radius: nullpx;
  margin-top: 21.64px;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.editmidcontent {
  height: 20px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  display: flex;
  align-items: center;
  color: #3b4a57;
}
.editmidcontent1 {
  position: static;
  height: 17px;
  left: 0px;
  right: -101.64px;
  top: 0px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #3b4a57;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 2px 0px;
}
.edittextblue {
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #0051c2;
}
.footerline {
  margin-top: 130.79px;
  margin-left: 29.99px;
  height: 0px;
  width: 362.03176879882955px;
}
.edittextbox {
  height: 20px;
  width: 72px;
  background-color: #f6f7f9;
  border-style: none;
  text-align: left;
  margin-right: 32px;
  border-color: none;
  outline-color: none;
  color: #0051c2;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  outline: none;
}
.peopleScreenPagination {
  padding: 0px;
}
#editConfigurationDialog .p-dialog-content {
  padding: 0%;
  width: 426.58px;
  height: 819.28px;
  border-radius: 16px !important;
}
.editConfigurationWrapper {
  display: flex;
  justify-content: space-between;
}
.editConfigurationHeader {
  padding-left: 40px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 74px;
  color: #1e3264;
  background-color: #edbdbd;
  height: 70.75px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.editPageFooterBody {
  /*height value is changed from 8vh to 7.5vh*/
  height: 69.5px;
  /*bottom value is changed from 5.4vh to 6vh*/
  bottom: 0vh;
  width: 426.48px;
  position: absolute;
  background-color: white;
  border-radius: 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* margin-right: 20px !important; */
}
.editPageCancelButton {
  margin-right: 4%;
  border: none;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #0051c2;
  cursor: pointer;
  background-color: white;
}
.editPageNextButton {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2px;
  margin-right: 30px;
}
.footercancel {
  position: relative;
  width: 105px;
  height: 50px;
  border: none;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #0051c2;
  cursor: pointer;
  background-color: white;
  justify-content: center;
}
.footerCreate {
  padding-left: 100px;
}
.contentsedit {
  padding-left: 40.59px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 154%;
  padding-right: 34.47px;
  padding-top: 10px;
}
