.greetingMsgContainer{
    padding-top: 5px;
    padding-left: 24px;
    margin-top: 18px;
    /* height: 200px;
    overflow-y:auto ; */
}
.noMsgStyle{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 154%;
    color: #73808C;
}
.wholeWrapperMsg{
    display: flex;
    margin-right: 25px;
    gap: 15px;
}
.gMsgDateWrapper{
    display: flex;
    justify-content: space-between;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 154%;
    color: #73808C;
    margin-bottom: 4px;
}
.gMsgHeaderWrapper{
    display: flex;
    justify-content: space-between;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 125%;
    color: #3B4A57;
}
.gMsgNEWStyle{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #008A00;
}
.gMsgDateHeaderWrapper{
    width: 100%;
}
.gMsgArrow{
    width: 15px;
    height: 10px;
    margin-right: 5px;
    cursor: pointer;
}
.gMsgBodyWrapper{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 154%;
    color: #3B4A57;
    padding-left: 63px;
    margin-right: 65px;
    margin-bottom: 5px;
    height: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-top: -5px;

}
.gMsgBodyWrapperExpand{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 154%;
    color: #3B4A57;
    padding-left: 63px;
    margin-right: 65px;
    margin-bottom: 5px;
    overflow: hidden;
    height: auto;
    animation: hideMe 2s ease;
    margin-top: -5px;
}
@keyframes hideMe{
    from {height: 0px;}
    to {height: auto;}
}