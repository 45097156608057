.badgeSelectionSearchBar {
  height: 72px;
  width: 361px;
  left: 608.81640625px;
  top: 146.1103515625px;
  border-radius: 16px;
}
.checkboxes {
  padding-left: 5px;
  padding-right: 292.49px;
}
.locationHeading {
  padding-left: 30.18px;
  padding-top: 34.64px;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.locationSelectionSearchBar {
  padding-left: 29.99px;
  padding-top: 21px;
  padding-right: 35.49px;
}
.locationCheckboxes {
  padding-top: 20.36px;
  padding-left: 29.99px;
}
.enabledCss {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  /* padding-left: 16px; */
}
.disabledCss {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #a7a7a7;
  /* padding-left: 16px; */
}
#badgeId-tab3 > div.locationSelectionSearchBar > div > div > label {
  color: #c2c9d1;
}
