#rewards_overlay > div:first-child {
  padding: 0%;
}
#saveCardOverLay {
  width: 40%;
  height: auto;
  box-shadow: 0px 8px 22px rgba(27, 31, 35, 0.07);
}
#rewards_overlay {
  width: 75%;
  height: auto;
  box-shadow: 0px 8px 22px rgba(27, 31, 35, 0.07);
}
#rewards_overlay
  > div:first-child
  > div:first-of-type
  > div:first-child
  > p:first-child {
  margin: 0px;
}
#rewards_overlay > div:first-child > div:first-of-type {
  padding: 0%;
  margin: 0px;
}
#saveCardOverLay > div:first-child > div:first-of-type {
  padding: 0%;
  margin: 0px;
  overflow-y: hidden;
}

#rewards_overlay > div:first-child > div:first-of-type > div:first-child {
  margin: 0px;
}
.avatarContainer {
  height: 46px;
  margin-top: 17px;
}
.newRewardBanner {
  background-color: #edbdbd;
  height: 74px;
}
.newRewardText {
  font-size: 25px;
  font-weight: bold;
  font-family: 'Montserrat';
  color: #1e3264;
  padding-left: 32px;
  padding-top: 22px;
  font-style: normal;
}
.badgeName {
  color: #000000;
  font-weight: 500;
  font-size: 14px;
  font-family: 'Montserrat';
  margin-top: 0px;
  margin-bottom: 0px;
}
.final-BadgeAwardMessage {
  line-height: 20.2px;
  font-weight: 400;
  font-size: 13px;
  color: #3b4a57;
  font-family: 'Montserrat';
}
.badgeDescription {
  color: #3b4a57;
  font-weight: normal;
  font-size: 13px;
  font-family: 'Montserrat';
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.2;
}
.image_style {
  border-radius: 16px;
  background-color: #f6f7f9;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 40px;
  padding-bottom: 32px;
  margin-right: 32px;
  box-shadow: inset 0px -2px 0px #c2c9d1;
}
.badgeOnBanner {
  position: relative;
  left: 75%;
  top: -30px;
  height: 88px;
  width: 88px;
  box-shadow: -2px -5px 5px rgba(27, 31, 35, 0.05);
  border-radius: 100px;
}
.momentText {
  font-size: 13px;
  color: #3b4a57;
  font-weight: 400;
  font-family: 'Montserrat';
  font-style: normal;
}
.buttonRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 30px;
  margin-top: 5px;
}
.cancelButton {
  margin-right: 20px;
  background-color: #ffffff;
  color: #0051c2;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 600;
  border: none;
}
.flexBox {
  display: flex;
  flex-direction: row;
}
.flexBoxNone {
  display: flex;
  flex-direction: row;
  display: none;
}
.dividerLineRewards {
  border: 1px solid #c2c9d1;
  width: auto;
  margin-right: 32px;
}
.selectRewardText {
  color: #3b4a57;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 600;
  font-style: normal;
}
.leftSectionTextContainer {
  color: #3b4a57;
  font-size: 13px;
  font-family: 'Montserrat';
  font-weight: 500;
  margin-left: 32px;
  width: 70%;
  height: 62vh;
}
.MessageTitleText {
  color: #3b4a57;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 600;
  margin-top: 0px;
  padding-top: 33.3px;
}

.userNameText {
  font-size: 14px;
  color: #1e3264;
  font-weight: 500;
  font-family: 'Montserrat';
  line-height: 17px;
  margin-top: 20px;
  margin-left: 15.98px;
}
.rewards-Text {
  font-size: 16px;
  font-weight: 600;
  font-family: #3b4a57;
  margin-top: 32.3px;
  margin-bottom: 19px;
}

.typePointsText {
  font-weight: 400;
  font-size: 13px;
  font-family: 'Montserrat';
  width: 172.87px;
  font-style: normal;
}
.badgeNamePointsText {
  font-weight: 600;
  font-style: 14px;
  font-family: 'Montserrat';
  font-size: normal;
}
.tabInnerContainer {
  height: 62vh;
  overflow-y: auto;
}
.recipientsText {
  font-size: 16px;
  font-weight: 600;
  color: #3b4a57;
  margin-top: 35px;
  direction: ltr;
  margin-left: 32px;
}
.recipient_Text {
  font-size: 16px;
  font-weight: 600;
  color: #3b4a57;
  margin-top: 35px;
}
.messageBox {
  background-color: #f6f7f9;
  height: 202.34px;
  width: 351px;
  /* margin-top: 40px; */
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  border-radius: 4px;
}
#tabStyle {
  display: flex;
  flex-direction: row;
  /* justify-content: space-around; */
  align-items: center;
  height: 74.3px;
  background-color: #d9bfad;
  margin-bottom: 0px;
}
#tabStyle > button:focus {
  box-shadow: none;
}
#selectBadgeTabActive {
  box-shadow: none;
  color: #3b4a57;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Montserrat';
}
#selectBadgeTabInActive {
  color: #3b4a57;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Montserrat';
}
#selectRecipientsTabActive {
  box-shadow: none;
  color: #3b4a57;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Montserrat';
}
#selectRecipientsTabInActive {
  color: #3b4a57;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Montserrat';
}
#rewardMsgTabActive {
  box-shadow: none;
  color: #3b4a57;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Montserrat';
}
#rewardMsgTabInActive {
  color: #3b4a57;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Montserrat';
}
#searchContainer > div:first-child > div:first-child {
  width: auto;
  height: 70px;
  box-shadow: inset 0px -2px 0px #c2c9d1;
  margin-right: 10px;
}
#recipientsTab {
  margin-left: 32px;
}
#inputContainer > div:first-child {
  width: auto;
  height: 72px;
  border-radius: 16px;
}
#inputContainer > div:first-child > div:first-child {
  background-color: #f6f7f9;
}
#inputContainer label {
  color: #73808c;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-family: 'Montserrat';
}
#messageContainer {
  margin-left: 32px;
}
#textAreaContainer > div:first-child {
  width: auto;
}
#textAreaContainer > div:first-child > div:first-child {
  background-color: #f6f7f9;
}
#textAreaContainer label {
  color: #73808c;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-family: 'Montserrat';
}
#textAreaContainer label + div {
  color: #262c30;
}

#loader .p-dialog-header {
  display: none;
}

#loader .p-dialog-content {
  background-color: transparent;
  padding: 0;
}

#loader {
  box-shadow: none;
}
#badgeAwardLoader {
  background-color: transparent;
}
#spinner {
  background-color: transparent;
}
.img_MsgContainer {
  width: 40px;
  aspect-ratio: 1;
  margin-top: 8px;
}
.msgTxt {
  color: #73808c;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-family: 'Montserrat';
}
#reward-nextButton > span {
  padding: 12px 24px;
  /* height: 40%; */
}

.badgeNameText {
  margin-left: 24px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #73808c;
}

.badgeMsgText {
  margin-top: 30px;
  margin-left: 24px;
  margin-right: 60px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #73808c;
}
.badgeTitleText {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #3b4a57;
}

#reward-nextButton > span {
  padding: 12px 24px;
}
#searchContainer > div > div > div > label {
  color: #c2c9d1;
}
.messageTitle{
  margin-right: 36.48px;
  resize: none;
}
#messageContainer > div > div.tabInnerContainer > div.messageTitle > div > div > label,#messageContainer > div > div.tabInnerContainer > div:nth-child(4) > div > div > div > label {
  color: #73808C !important;
}
.messageTitleStyle{
width: 310px;
font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 125%;
margin-left: 28px;
color: #73808C;
}
.messageBodyStyle{
width: 310px;
font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 20px;
margin-left: 28px;
color: #73808C;
}
.inputlength{
font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 17px;
text-align: right;
color: #262C30;
margin-right: 52.48px;
}