.tabheading {
  position: static;
  height: 20px;
  left: 0px;
  right: 45.21px;
  top: 18px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  display: flex;
  align-items: center;
  color: #3b4a57;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
}
.main-div {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-left: 25.01px;
  padding-right: 34.47px;
}
.main-div2 {
  display: flex;
  justify-content: space-evenly;
  margin-left: 30.29px;
}
.heading {
  height: 20px;
  left: 0px;
  top: 0px;
  border-radius: nullpx;
  margin-top: 57.92px;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.subheading {
  height: 20px;
  width: 222.2936553955078px;
  left: 0px;
  top: 20px;
  border-radius: nullpx;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.contents {
  padding-left: 30.01px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 154%;
  padding-right: 34.47px;
}
.databox {
  height: 72px;
  width: 104px;
  border-radius: 16px;
  background: #f6f7f9;
  margin-top: 37.92px;
  margin-right: 34.19px;
  padding-top: 26px;
  padding-left: 32px;
  padding-bottom: 26px;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  box-shadow: 0px -2px 0px 0px #c2c9d1 inset;
  color: #0051c2;
  border-style: none;
}
.databox1 {
  height: 72px;
  width: 104px;
  border-radius: 16px;
  background: #f6f7f9;
  margin-top: 5.34px;
  margin-right: 34.19px;
  padding-top: 26px;
  padding-left: 32px;
  padding-bottom: 26px;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  box-shadow: 0px -2px 0px 0px #c2c9d1 inset;
  color: #0051c2;
}
.heading1 {
  height: 20px;
  /*width: 278.6419372558594px;*/
  left: 0px;
  top: 0px;
  border-radius: nullpx;
  margin-top: 21.64px;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.midcontent {
  height: 20px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  display: flex;
  align-items: center;
  color: #3b4a57;
}
.midcontent1 {
  position: static;
  height: 17px;
  left: 0px;
  right: -101.64px;
  top: 0px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #3b4a57;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 2px 0px;
}
.textblue {
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #0051c2;
}
.footerline {
  margin-top: 130.79px;
  margin-left: 29.99px;
  height: 0px;
  width: 362.03176879882955px;
}
.textbox {
  height: 20px;
  width: 72px;
  background-color: #f6f7f9;
  border-style: none;
  text-align: left;
  margin-right: 32px;
  border-color: none;
  outline-color: none;
  outline: none;
  color: #0051c2;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}
.peopleScreenPagination {
  padding: 0px;
}
.midimage {
  margin: 0px 16px 0px 16px;
  width: 44px;
  height: 44px;
  display: flex;
  /* align-items: center; */
  color: #3b4a57;
  max-width: 125px;
  padding-right: 50px;
}
.targetPointsCss {
  color: #0051c2;
}
.targetPointsPTSCss {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  color: #3b4a57;
}
#badgeId-tab2 > div.main-div > div.midcontent1 > div {
  margin-left: -11px !important;
}
