.weatherScreenHeaderContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffa894;
  height: 74px;
  padding: 0 24px;
  position: fixed;
  width: 55.95%;
  z-index: 3;
}

.WeatherScreenHeader {
  font-family: Montserrat;
  font-weight: bold;
  font-size: 25px;
  line-height: 32px;
  color: #1e3264;
}

.weatherContainer {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.weatherLeftSection {
  width: 66.7%;
  background-color: #fff;
  overflow-y: scroll;
  padding-bottom: 8vh;
}

.weatherRightSection {
  width: 33.3%;
  background-color: #fff;
  overflow-y: scroll;
}

.weatherScreenDate {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #1e3264;
}

.weatherHeading {
  padding: 35px 0 0px 40px;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #3b4a57;
  margin-top: 75px;
}

.weatherBadgeImage {
  width: 88px;
  aspect-ratio: 1;
  margin-left: 16px;
}

.radioLabelHeading {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.radioLabelDescription {
  font-family: Montserrat;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: #3b4a57;
}

#weatherNotifyButton > span {
  padding: 12px 24px;
}
.resetText {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  padding: 0 24px;
  color: #a7a7a7;
  cursor: pointer;
}

.resetTextEnable {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  padding: 0 24px;
  color: #0051c2;
  cursor: pointer;
}
#markedRadio > div > div {
  border: 0.5rem solid #a7a7a7;
}
.weather-source {
  color: #73808c;
  font-family: 'Montserrat';
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 10px;
  letter-spacing: 0em;
}
@media (max-width: 1370px) {
  .weatherScreenHeaderContainer {
    width: 55.5%;
  }
}
